export default function filterTabs(){
    var myTabs = document.querySelectorAll(".filter__type");

    function myTabClicks(tabClickEvent) {

        for (var i = 0; i < myTabs.length; i++) {
            myTabs[i].classList.remove("btn_active");
        }

        var clickedTab = tabClickEvent.currentTarget;

        clickedTab.classList.add("btn_active");

        var myContentPanes = document.querySelectorAll(".tab");

        for (let i = 0; i < myContentPanes.length; i++) {
            myContentPanes[i].classList.remove("tab_show");
        }

        var anchorReference = tabClickEvent.target;
        var activePaneId = anchorReference.getAttribute("data-id");
        var activePane = document.getElementsByClassName(activePaneId);

        for(let i = 0; i < activePane.length; i++){
            activePane[i].classList.add("tab_show");
        }
    }

    for (let i = 0; i < myTabs.length; i++) {
        myTabs[i].addEventListener("click", myTabClicks);
    }
};