import CSSelect from "./cs_select";

export default function formFilter(elem) {

    let dataJson = JSON.parse(elem.getAttribute('data-json'));


    let getJsonBy = (id) => {
        let json = {};
        dataJson.forEach(dataJsonItem => {
            if (+dataJsonItem.id === +id){
                json = dataJsonItem.json;
            }
        });
        return json;
    };

    let insertAfter = (elem, refElem) => {
      let parent = refElem.parentNode;
      let next = refElem.nextSibling;
      if (next) {
        return parent.insertBefore(elem, next);
      } else {
        return parent.appendChild(elem);
      }
    };

    elem.addEventListener('change', (e) => {
        // get value
        if(elem !== null && elem !== undefined) {
            e.preventDefault();
        }
        let anchorElem = document.querySelector('#' + elem.getAttribute('data-filter_id'));
        let anchorElemChild = document.querySelector('#child_for_' + elem.getAttribute('data-filter_id'));

        let json = getJsonBy(e.target.value);

        // remove old select

        // remove child
        if(anchorElemChild !== null && anchorElemChild !== undefined) {
            anchorElemChild.remove();
        }

        // update old elem
        if(anchorElemChild !== null && anchorElemChild !== undefined) {
        let newElem = anchorElem.cloneNode();
        insertAfter(newElem, anchorElem);
        anchorElem.remove();

        newElem.setAttribute('data-json',JSON.stringify(json));

        // build new select

        formFilter(newElem);

        CSSelect(newElem, true);

    }


})}

