import CSSelect from './componetnts/cs_select';
import CSFilter from './componetnts/form-filter';
import CSInputStreet from './componetnts/cs_input_street';
import square from './componetnts/cs_input_square';
import priceMobile from './componetnts/cs_price_mobile';
import squareMobile from './componetnts/cs_square_mobile';
import modal from './componetnts/modal';
import filterTabs from './componetnts/filter';
import mobileMenu from './componetnts/mobile_menu';
import vacansyBtn from './componetnts/vacansy_btn';
import favorite from './componetnts/favorite';
import './componetnts/autocomplete/dadataInput'
import 'google-maps-api';
import goodsSlider from './componetnts/goodsSlider';
import price from './componetnts/price';
import enter from './componetnts/cs_checkbox';
import map from './componetnts/map/map'
import workerFilter from './componetnts/workers'
import goodsCSSelect from './componetnts/goodsCSSelect';
import settingsPanel from './componetnts/settingsPanel'
import requestArticle from "./componetnts/requestArticle";
import print from './componetnts/print'
import video from './componetnts/video'
import requestObjects from './componetnts/requestObjects';
import geolocation from './componetnts/geolocation';
import requestGoods from "./componetnts/requestGoods";
import { WOW } from 'wowjs'
import numberAnimation from './componetnts/number_animation';
import AOS from 'aos';
import ScrollReveal from 'scrollreveal';
import latentLinkFollowing from './componetnts/latent_link_following';
import {clientsUpload} from "./componetnts/clientsUpload";
import {setFilterImage} from "./componetnts/setFilterImage";

ScrollReveal({ duration: 500 });
ScrollReveal().reveal('.cat-tile1', { distance: '-60px', delay: '500' });
ScrollReveal().reveal('.cat-tile2', { distance: '-40px', delay: '500' });
ScrollReveal().reveal('.cat-tile3', { distance: '-20px', delay: '500' });

numberAnimation();
AOS.init();
//
// var wow = new WOW(
//     {
//         boxClass:     'wow',      // animated element css class (default is wow)
//         animateClass: 'animated', // animation css class (default is animated)
//         offset:       0,          // distance to the element when triggering the animation (default is 0)
//         mobile:       true,       // trigger animations on mobile devices (default is true)
//         live:         true,       // act on asynchronously loaded content (default is true)
//         callback:     function(box) {
//             // the callback is fired every time an animation is started
//             // the argument that is passed in is the DOM node being animated
//         },
//         scrollContainer: null // optional scroll container selector, otherwise use window
//     }
// );
// wow.init();

goodsSlider();
geolocation();

let filter =  document.querySelector('.search-container');
    if(filter !== undefined && filter !== null){

    let submitBtn = filter.querySelector('.btn_submit'),
    inputMap = filter.querySelector('.search-container-input_require');

    if(submitBtn !== null && submitBtn !== undefined){
    submitBtn.addEventListener('click', (e) => {
        if(inputMap !== null && inputMap !== undefined){
           inputMap.remove();
        }

        filter.querySelectorAll('.search-container-input').forEach(item => {
            if (item.value === "0"){
                item.value = "";
            }
        })
    })}
    }


if (filter !== undefined && filter !== null){
let mapBtn = filter.querySelector('.btn_map');
if(mapBtn !== null && mapBtn !== undefined){
let inputRequire = document.querySelector('.search-container-input_require');

mapBtn.addEventListener('click', (e) => {
    inputRequire.value = "True";
    filter.querySelectorAll('.search-container-input').forEach(item => {
        if (item.value === "0"){
            item.value = ""
        }
    })
})}}


goodsCSSelect();
workerFilter();
requestGoods();
requestArticle();

map();
video();


let select = document.querySelectorAll('.search-container-input');
select.forEach(filterItem => {
    CSSelect(filterItem);
});

let filters = document.querySelectorAll('.custom_filter_select');
filters.forEach(filterItem => {
    CSFilter(filterItem);
});

price();
enter();
square();
favorite();

document.querySelectorAll('.search-container__item_street').forEach((item) => {
    CSInputStreet(item);
});

filterTabs();
priceMobile();
squareMobile();
mobileMenu();
vacansyBtn();
modal();
settingsPanel();
print();
requestObjects();
latentLinkFollowing();
clientsUpload();
setFilterImage();

