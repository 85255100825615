export default function workerFilter(){
    let filterInput = document.querySelector('.employes-search__input');
    let button = document.querySelector('button.btn');

        if (filterInput !== null && filterInput !== undefined){
        let  employesNamesContainer = document.querySelectorAll('.about-team-item');

        function clickTrigger(){
            employesNamesContainer.forEach(name => {

                let employesNames = name.querySelector('.about-team-item__name');

                let employesName = employesNames.textContent.toLowerCase();

                if(~employesName.indexOf(filterInput.value.toLowerCase())){
                    name.classList.remove('hidden');
                } else {
                     name.classList.add('hidden');
                }
        filterInput.addEventListener('input', (e) => {
            if(filterInput.value === ''){
                name.classList.remove('hidden');
            }
        });

        })
        }

        button.addEventListener('click', (e) => {
            e.preventDefault();
            clickTrigger();
        });
        filterInput.addEventListener('keyup', (e) =>{
            if(e.keyCode == 13){
               clickTrigger()
            }
        })
    }
}