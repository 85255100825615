const clientsClasses = {
    button: '.companies-upload-js',
}

let partnersOffset = 4;
let clientsOffset = 4;
const limit = 4;

const setRequestUrl = (type = 'clients') => {
    return `${type}-ajax?offset=${type === "clients" ? clientsOffset : partnersOffset}&limit=${limit}`
}

const clientTemplate = (data = {}) => {
    let element;
    element =
        `
<div class="companies__item">
            <div class="companies__image">
                <img src="${data.img}"
                     alt="${data.title}">
            </div>
            <div class="companies__description">
                <div class="companies__title">
                    ${data.title || '-'}
                </div>
                <div class="companies__text">
                    ${data.description || '-'}
                </div>
            </div>
</div>
            `
    return element
}

const getRequest = (type, elem) => {
    fetch(setRequestUrl(type), {
        method: 'GET',
    })
        .then(response => response.json().then(data => ({status: response.status, body: data})))
        .then(data => {
            data.body.data.forEach((item) => {
                elem.insertAdjacentHTML('beforebegin', clientTemplate(item))
            })
            type === "clients" ? clientsOffset = clientsOffset + 4 : partnersOffset = partnersOffset + 4
           data.body.end ? elem.remove() : null
        })
}

export const clientsUpload = () => document.querySelectorAll(clientsClasses.button).forEach(button => button.addEventListener('click', () => getRequest(button.getAttribute('data-type'), button)));
