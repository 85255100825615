export default function video(){
    let videoBtn = document.querySelector('.header-project-panel__item__video');
    if(videoBtn !== null){
        videoBtn.addEventListener('click', (e) => {
            e.preventDefault();
            let videoSrc = videoBtn.getAttribute('data-video');
            let videoModal = document.createElement('div'),
                videoIframe = document.createElement('iframe'),
                videoClose = document.createElement('a'),
                overlay = document.querySelector('.overlay');

            videoModal.classList.add('modal', 'modal-video');
            videoIframe.classList.add('iframe-video');
            videoIframe.setAttribute('src', videoSrc);
            overlay.classList.add('overlay_active');

            overlay.appendChild(videoModal);
            videoModal.appendChild(videoIframe);
            videoModal.appendChild(videoClose);

            videoClose.innerHTML = '<a class="modal-close">\n' +
                            '<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<svg width="36px" height="36px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
                            '<path d="M22.47375,19.94125 L36.98375,5.495 C37.67,4.81125 37.67375,3.705 36.99125,3.0175 C36.3075,2.32875 35.2025,2.3275 34.51625,3.01 L20.00125,17.45875 L5.61375,3.01625 C4.93375,2.3325 3.825,2.33 3.14,3.0125 C2.455,3.69625 2.45375,4.805 3.13625,5.49125 L17.52,19.93 L3.01625,34.3675 C2.33,35.05125 2.32625,36.1575 3.00875,36.845 C3.35,37.18875 3.8,37.36125 4.25,37.36125 C4.69625,37.36125 5.1425,37.19125 5.48375,36.85125 L19.99,22.41 L34.50875,36.98375 C34.85125,37.32875 35.3,37.5 35.74875,37.5 C36.19625,37.5 36.64375,37.32875 36.985,36.98875 C37.66875,36.305 37.67125,35.19875 36.98875,34.5125 L22.47375,19.94125 Z" id="Path"></path>\n' +
                            '\n' +
                            '</svg>\n' +
                            '</a>'

        })
    }
    setTimeout(function(){
        let video = document.querySelector('.header-video-container__video');
        video ? video.play() : null;
    }, 1000);
}