export default function favorite() {
    let goodsItemContainer = document.querySelectorAll('.good-state'),
        goodItem = document.querySelectorAll('.goods-item-container'),
        btnRemove = document.querySelector('.btn_remove'),
        favoritesResult = document.querySelector('.favorites_result'),
        addFavoriteCluster = document.querySelector('.add-favorite'),
        favoriteNumber = document.querySelector('.favorite__number'),
        favoriteNumberId = favoriteNumber.getAttribute('data-id');

    let checkNumbers = () => {
        let allFavoriteNumbers = document.querySelectorAll('.favorite__number');

        allFavoriteNumbers.forEach(item => {
            item.innerHTML = `(${favoriteNumberId})`;
        })

    };


    checkNumbers();

    function noCategory(){
        let favoriteContainer = document.querySelector('.favorite');
        if(favoriteContainer !== null){
            let goodItemSell = favoriteContainer.querySelectorAll('.goods-item-container.prodazha'),
                goodItemRent = favoriteContainer.querySelectorAll('.goods-item-container.arenda'),
                goodItemRentBuisness = favoriteContainer.querySelectorAll('.goods-item-container.arendnyj-biznes');

        if(goodItemSell.length === 0){
            let emptySell = document.createElement('div'),
                goodsContainer = document.querySelector('.goods-container');

            if(goodsContainer !== null){

            goodsContainer.appendChild(emptySell);

            emptySell.classList.add('tab', 'prodazha', 'empty-content');

            emptySell.innerHTML = '<img src="../../../../static/assets/img/bgs/empty.png" alt="">'

                }
        }

        if(goodItemRent.length === 0){
            let emptySell = document.createElement('div'),
                goodsContainer = document.querySelector('.goods-container');

            if(goodsContainer !== null){

            goodsContainer.appendChild(emptySell);

            emptySell.classList.add('tab', 'arenda', 'empty-content');

            emptySell.innerHTML = '<img src="../../../../static/assets/img/bgs/empty.png" alt="">'

                }

        }

        if(goodItemRentBuisness.length === 0){
            let emptySell = document.createElement('div'),
                goodsContainer = document.querySelector('.goods-container');

            if(goodsContainer !== null){

            goodsContainer.appendChild(emptySell);

            emptySell.classList.add('tab', 'arendnyj-biznes', 'empty-content');

            emptySell.innerHTML = '<img src="../../../../static/assets/img/bgs/empty.png" alt="">'

                }
        }
    }
        }
    noCategory();


    function emptyContentGoods() {
        let content = document.querySelector('.goods');
        if (content !== null && content !== undefined) {
            let goodResult = content.querySelector('.goods-result'),
                goodsFilter = content.querySelector('.goods-filter'),
                goodsItem = content.querySelectorAll('.goods-item-container'),
                goodsContainer = content.querySelector('.goods-container');

            if (goodsItem.length < 1 && goodsFilter !== null) {
                goodsFilter.classList.add('hidden');
                goodsContainer.innerHTML = '<div class="empty-content">' +
                    '<img src="../../../../static/assets/img/bgs/empty.png" alt="">' +
                    '</div>';
            goodResult.innerHTML = 'Объявлений не найдено. Попробуйте поискать с другими параметрами.';
            }
            else
                {
                    if(goodsFilter !== null && goodResult !== null){
                        goodResult.classList.remove('hidden');
                        goodsFilter.classList.remove('hidden');
                    }
                }
            }
        }

    function emptyContentFavorite() {
        let content = document.querySelector('.favorite');
        if (content !== null && content !== undefined) {
            let goodResult = content.querySelector('.goods-result'),
                goodsFilter = content.querySelector('.goods-filter'),
                goodsItem = content.querySelectorAll('.goods-item-container'),
                goodsContainer = content.querySelector('.goods-container');

            if (goodsItem.length < 1 && goodsFilter !== null) {
                goodsFilter.classList.add('hidden');
                goodsContainer.innerHTML = '<div class="empty-content">' +
                    '<img src="../../../../static/assets/img/bgs/empty.png" alt="">' +
                    '</div>';

            goodResult.innerHTML = 'Объявления пока не добавлены, но вы можете воспользоваться <a href="/" class="goods-result__link">поиском</a>'
            }
            else
                {
                    if(goodsFilter !== null && goodResult !== null){
                        goodResult.classList.remove('hidden');
                        goodsFilter.classList.remove('hidden');
                    }
                }
            }
        }

    emptyContentGoods();
    emptyContentFavorite();

    let favoriteMobile = document.querySelector('.header-mobile-favorite__icon');
    let favoriteIcon = document.querySelector('.header-item__favorite');

    let iconAdded = '                        <span class="favorite__icon">\n' +
        '                            <svg class="active" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
        '                                <g id="Icon/Bookmark/Normal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
        '                                    <path class="book_border" d="M3.74999999,1.5 L14.25,1.5 C14.6642136,1.50000001 15,1.83578645 15,2.25000001 L15,15.75 C15.0000354,16.0173655 14.8577358,16.2645325 14.6265,16.39875 C14.3953995,16.5334634 14.1099062,16.5343216 13.878,16.401 L9,13.61325 L4.122,16.401 C3.88990771,16.5335749 3.60479866,16.5326116 3.37360757,16.3984713 C3.14241648,16.264331 3.00008621,16.0172881 3.00000001,15.75 L3.00000001,2.25000001 C3.00000001,1.83578645 3.33578644,1.50000001 3.74999999,1.5 Z M13.5,14.457 L13.5,3.00000001 L4.5,3.00000001 L4.5,14.457 L8.62799999,12.099 C8.85858869,11.9678434 9.14114735,11.9675586 9.37200001,12.09825 L13.5,14.457 Z" id="Combined-Shape" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
        '                                    <path class="book_inside" d="M14,15.5 L9.41333334,12.8235795 C9.15683039,12.6752869 8.84287632,12.6756101 8.58666666,12.8244305 L4,15.5 L4,2.5 L14,2.5 L14,15.5 Z" id="Path" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
        '                                </g>\n' +
        '                            </svg>\n' +
        '                        </span>',
        icon = '                        <span class="favorite__icon">\n' +
            '                            <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
            '                                <g id="Icon/Bookmark/Normal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
            '                                    <path class="book_border" d="M3.74999999,1.5 L14.25,1.5 C14.6642136,1.50000001 15,1.83578645 15,2.25000001 L15,15.75 C15.0000354,16.0173655 14.8577358,16.2645325 14.6265,16.39875 C14.3953995,16.5334634 14.1099062,16.5343216 13.878,16.401 L9,13.61325 L4.122,16.401 C3.88990771,16.5335749 3.60479866,16.5326116 3.37360757,16.3984713 C3.14241648,16.264331 3.00008621,16.0172881 3.00000001,15.75 L3.00000001,2.25000001 C3.00000001,1.83578645 3.33578644,1.50000001 3.74999999,1.5 Z M13.5,14.457 L13.5,3.00000001 L4.5,3.00000001 L4.5,14.457 L8.62799999,12.099 C8.85858869,11.9678434 9.14114735,11.9675586 9.37200001,12.09825 L13.5,14.457 Z" id="Combined-Shape" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
            '                                    <path class="book_inside" d="M14,15.5 L9.41333334,12.8235795 C9.15683039,12.6752869 8.84287632,12.6756101 8.58666666,12.8244305 L4,15.5 L4,2.5 L14,2.5 L14,15.5 Z" id="Path" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
            '                                </g>\n' +
            '                            </svg>\n' +
            '                        </span>';


    if (favoritesResult !== undefined && favoritesResult !== null) {
        favoritesResult.textContent = goodItem.length;
    }

    function addFavoriteAdd(id, successCb = () => {
    }) {
        const formDataAdd = new FormData();

        formDataAdd.append('object_id', id);
        formDataAdd.append('type', 'POST');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataAdd
        })
            .then((res) => {
                successCb();
            })
            .catch((error) => console.log(error));
    }

    function addFavoriteRemove(id, successCb = () => {
    }) {
        const formDataRemove = new FormData();

        formDataRemove.append('object_id', id);
        formDataRemove.append('type', 'DELETE');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataRemove
        })
            .then((res) => {
                successCb();
            })
            .catch((error) => console.log(error));
    }

    function favoriteRemoveAll(id, successCb = () => {
    }) {
        const formDataRemove = new FormData();

        formDataRemove.append('object_id', id);
        formDataRemove.append('type', 'DELETE');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataRemove
        })
            .then((res) => {
                successCb();
            })
            .catch((error) => console.log(error));
    }


    goodsItemContainer.forEach(function (favoriteItem) {
        let addFavorite = favoriteItem.querySelector('.favorite-js'),
            itemId = favoriteItem.getAttribute('data-map');


        if (addFavorite !== undefined && addFavorite !== null) {

            addFavorite.addEventListener('click', function (e) {

                if (addFavorite.classList.contains('add-favorite')) {
                    e.preventDefault();
                    addFavoriteAdd(itemId, () => {
                        favoriteNumberId = +favoriteNumberId + 1;
                        checkNumbers();
                        addFavorite.classList.remove('add-favorite');
                        addFavorite.classList.add('remove-favorite');
                        addFavorite.innerHTML = iconAdded + "Удалить из избранного";
                        favoriteIcon.classList.add('header-item__favorited');
                        favoriteMobile.classList.add('active');
                        noCategory()
                    });
                }

                if (addFavorite.classList.contains('remove-favorite')) {
                    e.preventDefault();
                    addFavoriteRemove(itemId, () => {
                        favoriteNumberId = +favoriteNumberId - 1;
                        checkNumbers();
                        addFavorite.classList.remove('remove-favorite');
                        addFavorite.classList.add('add-favorite');
                        addFavorite.innerHTML = icon + "Добавить в избранное";
                        if (favoriteNumberId === 0) {
                            favoriteIcon.classList.remove('header-item__favorited');
                            favoriteMobile.classList.remove('active');
                        }
                        noCategory()
                    })
                }

                if (favoriteNumberId > 0) {
                    favoriteIcon.classList.add('header-item__favorited');
                    favoriteMobile.classList.add('active');
                } else if (favoriteNumberId === 0) {
                    favoriteIcon.classList.remove('header-item__favorited');
                    favoriteMobile.classList.remove('active');
                }
            })
        }
    });


    goodsItemContainer.forEach(function (favoriteItem) {

        let removeFavorite = favoriteItem.querySelector('.remove-favorite'),
            itemId = favoriteItem.getAttribute('data-map');

        const removeData = new FormData();

        removeData.append('object_id', itemId);
        removeData.append('type', 'DELETE');

        if (removeFavorite !== undefined && removeFavorite !== null) {
            removeFavorite.addEventListener('click', function (e) {
                favoriteNumber.textContent = favoriteNumberId;

                e.preventDefault();
                fetch('/catalog/add-object-in-favorite', {
                    method: 'POST',
                    body: removeData
                })
                    .then((res) => {
                        if (removeFavorite.classList.contains('remove-favorite')) {
                            favoriteNumberId = +favoriteNumberId - 1;
                            checkNumbers();
                            favoritesResult.innerHTML = favoriteItem.length;
                            if (favoritesResult.innerHTML === "undefined") {
                                favoritesResult.innerHTML = '0'
                            }
                            if (favoriteNumberId > 0) {
                                favoriteIcon.classList.add('header-item__favorited');
                                favoriteMobile.classList.add('active');
                            } else if (favoriteNumberId === 0) {
                                favoriteIcon.classList.remove('header-item__favorited');
                                favoriteMobile.classList.remove('active');
                            }
                            favoriteItem.remove();
                            emptyContentGoods();
                            emptyContentFavorite();
                        }
                    })
                    .catch((error) => console.log(error))

            })
        }

    });

    let goodPrintContainer = document.querySelector('.good-print-panel');
    if(goodPrintContainer !== null){
        let addFavorite = goodPrintContainer.querySelector('.favorite-js'),
            goodsItemContainer = document.querySelector('.good-state'),
            itemId = goodsItemContainer.getAttribute('data-map');

        if (addFavorite !== undefined && addFavorite !== null) {

            addFavorite.addEventListener('click', function (e) {

                if (addFavorite.classList.contains('add-favorite')) {

                    addFavoriteAdd(itemId, () => {
                        favoriteNumberId = +favoriteNumberId + 1;
                        checkNumbers();
                        addFavorite.classList.remove('add-favorite');
                        addFavorite.classList.add('remove-favorite');
                        favoriteIcon.classList.add('header-item__favorited');
                        favoriteMobile.classList.add('active');
                        addFavorite.innerHTML = iconAdded;

                    })
                } else if (addFavorite.classList.contains('remove-favorite')) {
                    addFavoriteRemove(itemId, () => {
                        favoriteNumberId = +favoriteNumberId - 1;
                        checkNumbers();
                        addFavorite.classList.add('add-favorite');
                        addFavorite.classList.remove('remove-favorite');
                        addFavorite.innerHTML = icon;
                    });
                    if (favoriteNumberId > 0) {
                        favoriteIcon.classList.add('header-item__favorited');
                        favoriteMobile.classList.add('active');
                    } else if (favoriteNumberId === 0) {
                        favoriteIcon.classList.remove('header-item__favorited');
                        favoriteMobile.classList.remove('active');
                    }
                }
            })
        }
    }

    if(goodsItemContainer !== null){

        goodsItemContainer.forEach(item => {
            let addFavorite = item.querySelector('.goods-item-description__favorite__book.favorite-js'),
                itemId = item.getAttribute('data-map');

            if(addFavorite !== null){

            addFavorite.addEventListener('click', function (e) {
                e.stopPropagation();
                e.preventDefault();

                if (addFavorite.classList.contains('add-favorite')) {

                    addFavoriteAdd(itemId, () => {
                        favoriteNumberId = +favoriteNumberId + 1;
                        checkNumbers();
                        addFavorite.classList.remove('add-favorite');
                        addFavorite.classList.add('remove-favorite');
                        favoriteIcon.classList.add('header-item__favorited');
                        favoriteMobile.classList.add('active');
                        addFavorite.innerHTML = iconAdded;

                    })
                } else if (addFavorite.classList.contains('remove-favorite')) {
                    addFavoriteRemove(itemId, () => {
                        favoriteNumberId = +favoriteNumberId - 1;
                        checkNumbers();
                        addFavorite.classList.add('add-favorite');
                        addFavorite.classList.remove('remove-favorite');
                        addFavorite.innerHTML = icon;
                    });
                    if (favoriteNumberId > 0) {
                        favoriteIcon.classList.add('header-item__favorited');
                        favoriteMobile.classList.add('active');
                    } else if (favoriteNumberId === 0) {
                        favoriteIcon.classList.remove('header-item__favorited');
                        favoriteMobile.classList.remove('active');
                    }
                }
            })}
        })
    };


    if (btnRemove !== undefined && btnRemove !== null) {
        btnRemove.addEventListener('click', (e) => {
            let overlay = document.querySelector('.overlay'),
                body = document.querySelector('body'),
                removeContent = '<a class="modal-close">\n' +
                    '<?xml version="1.0" encoding="UTF-8"?>\n' +
                    '<svg width="36px" height="36px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path d="M22.47375,19.94125 L36.98375,5.495 C37.67,4.81125 37.67375,3.705 36.99125,3.0175 C36.3075,2.32875 35.2025,2.3275 34.51625,3.01 L20.00125,17.45875 L5.61375,3.01625 C4.93375,2.3325 3.825,2.33 3.14,3.0125 C2.455,3.69625 2.45375,4.805 3.13625,5.49125 L17.52,19.93 L3.01625,34.3675 C2.33,35.05125 2.32625,36.1575 3.00875,36.845 C3.35,37.18875 3.8,37.36125 4.25,37.36125 C4.69625,37.36125 5.1425,37.19125 5.48375,36.85125 L19.99,22.41 L34.50875,36.98375 C34.85125,37.32875 35.3,37.5 35.74875,37.5 C36.19625,37.5 36.64375,37.32875 36.985,36.98875 C37.66875,36.305 37.67125,35.19875 36.98875,34.5125 L22.47375,19.94125 Z" id="Path"></path>\n' +
                    '\n' +
                    '</svg>\n' +
                    '</a>' +
                    '<div class="modal-title h3">Очистка избранного</div>' +
                    '<div class="modal-text">Вы действительно хотите удалить все добавленные в избранное объекты?</div>' +
                    '<div class="modal-remove_buttons">' +
                    '<a class="btn btn_remove">Удалить все</a>' +
                    '<a class="btn btn_white">Отменить</a>' +
                    '</div>';

            e.preventDefault();

            let modal = document.createElement('div');
            modal.classList.add('modal', 'modal-remove');
            modal.innerHTML = removeContent;
            body.style.overflow = 'hidden';
            overlay.appendChild(modal);
            overlay.classList.add('overlay_active');

            let btnReject = modal.querySelector('.btn_remove');
            let btnAccept = modal.querySelector('.btn_white');

            btnReject.addEventListener('click', (e) => {
                e.preventDefault();
                favoriteRemoveAll('all', () => {
                    removeAll();
                    modal.remove();
                    favoriteNumberId = '0';
                    favoriteIcon.classList.remove('header-item__favorited');
                    checkNumbers();
                    favoriteMobile.classList.remove('active');
                    emptyContentGoods();
                    emptyContentFavorite();
                })

            });
            btnAccept.addEventListener('click', (e) => {
                e.preventDefault();
                modal.remove();
            })
        })
    }

    if (favoriteNumberId > 0) {
        favoriteIcon.classList.add('header-item__favorited');
        favoriteMobile.classList.add('active');
    } else if (favoriteNumberId === 0) {
        favoriteIcon.classList.remove('header-item__favorited');
        favoriteMobile.classList.remove('active');
    }

    function removeAll() {
        goodItem.forEach(function (good) {
            good.remove();
        })
    }

}