export default function enter(){
    let priceFrom = document.createElement('div'),
        priceTo = document.createElement('div'),
        priceContainer = document.querySelector('.search-container__item_enter'),
        enterId = document.querySelector('#enter');

    if(priceContainer === null){
        return false
    };


    let insertAfter = (elem, refElem) => {
      let parent = refElem.parentNode;
      let next = refElem.nextSibling;
      if (next) {
        return parent.insertBefore(elem, next);
      } else {
        return parent.appendChild(elem);
      }
    };


   let  enterInput = priceContainer.querySelector('.search-container__item__input'),
        enterItemContainer = document.createElement('div'),
        mainEnter = document.createElement('input'),
        otherEnter = document.createElement('input'),
        mainEnterSetAttr = mainEnter.setAttribute('data-enter', '2'),
        otherEnterSetAttr = otherEnter.setAttribute('data-enter', '1');

        mainEnter.classList.add('enter');
        mainEnter.setAttribute('id', 'main_enter');
        otherEnter.classList.add('enter');
        otherEnter.setAttribute('id', 'other_enter');
        enterItemContainer.classList.add('search-container__item__checkboxes');

        enterInput.appendChild(priceFrom);
        enterInput.appendChild(priceTo);
        priceContainer.appendChild(enterItemContainer);
        enterItemContainer.appendChild(mainEnter);
        enterItemContainer.appendChild(otherEnter);

        mainEnter.setAttribute('type', 'checkbox');
        mainEnter.setAttribute('value', 'Отдельный вход');
        otherEnter.setAttribute('type', 'checkbox');
        otherEnter.setAttribute('value', 'Общий вход');

        priceFrom.innerHTML = 'Вход в помещение';

        let allEnterInput = enterItemContainer.querySelectorAll('.enter');

        allEnterInput.forEach(item => {

            let label = document.createElement('label');

             label.setAttribute('for', item.getAttribute('id'));

            label.textContent = item.getAttribute('value');

            insertAfter(label, item);
        });


    let setValue = function() {

         if(enterId.value === '2,1') {
            mainEnter.setAttribute('checked', true);
            otherEnter.setAttribute('checked', true);
        } else if(enterId.value === "1,2" && enterId.value === "2,1"){
            mainEnter.setAttribute('checked', true);
            otherEnter.setAttribute('checked', true);
        } else if(enterId.value === '2'){
            mainEnter.setAttribute('checked', true);
        } else if (enterId.value === '1'){
            otherEnter.setAttribute('checked', true);
        }

        // (mainEnterValue , otherEnterValue);
        if (!mainEnter.checked && !otherEnter.checked) {
            enterInput.innerHTML = 'Вход в помещение';
            enterId.value = '';
        } else if((mainEnter.checked && otherEnter.checked) || (otherEnter.checked && mainEnter.checked)) {
            enterInput.innerHTML = mainEnter.value + ', ' + otherEnter.value;
            enterId.value = mainEnter.getAttribute('data-enter') + ',' + otherEnter.getAttribute('data-enter');
        } else if(mainEnter.checked){
            enterInput.innerHTML = mainEnter.value;
            enterId.value = mainEnter.getAttribute('data-enter');
        } else if (otherEnter.checked){
            enterInput.innerHTML = otherEnter.value;
            enterId.value = otherEnter.getAttribute('data-enter');

        }
                // inputEnter.value = selectDropdownInput.textContent;
    };

    setValue();

    mainEnter.addEventListener('change', () =>{
        setValue();
    });
    otherEnter.addEventListener('change', () => {
        setValue();
    });

};