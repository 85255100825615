export default  function priceMobile(){
    let priceFrom = document.getElementsByClassName('search-container__item_range')[0];
    let priceTo = document.getElementsByClassName('search-container__item_range')[1];

    if (priceFrom !== undefined && priceFrom !== null && priceTo !== undefined && priceTo !== null) {

    let dataIdFrom = priceFrom.getAttribute('data-id');
    let dataIdTo = priceTo.getAttribute('data-id');

    let inputFrom = document.getElementById(dataIdFrom);
    let inputTo = document.getElementById(dataIdTo);

    let buyInputFrom = priceFrom.getElementsByClassName('buy-price-mobile_from')[0];
    let buyInputTo = priceTo.querySelector('.buy-price-mobile_to');

    if (buyInputFrom !== undefined && buyInputFrom !== null) {
        buyInputFrom.addEventListener('change', () => {
            inputFrom.value = buyInputFrom.value;
        });
    }
    if (buyInputTo !== undefined && buyInputTo !== null){
    buyInputTo.addEventListener('change', () => {
        inputTo.value = buyInputTo.value;
    })}
    } else {
        return false
    }
};