export default function goodsCSSelect(){
    let inputSelect = document.querySelector('.search-container__item_select-js');
    if(inputSelect !== null && inputSelect !== undefined){
    let inputSelectContent = inputSelect.querySelector('.search-container__item__text');
    let selected = inputSelectContent.querySelector('.selected');

        inputSelect.addEventListener('click', (e) => {
            inputSelect.classList.toggle('search-container__item_active')
        });

        inputSelectContent.addEventListener('click', (e) => {
            e.stopPropagation();
        });

    if(selected !== null && selected !== undefined){
    selected.addEventListener('click', (e) => {
        e.preventDefault();
    })
    }
    }
}