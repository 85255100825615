let BuildClusterInfo = (cluster, allMarkers, map, OverlayViewPrototype) => {

    let ClusterInfo = function () {

        this.ItemTmpl = '\n' +
            '<div class="map-cluster-item">\n' +
            ' 				<div class="map-cluster-item__favorite">' +
            '					<svg class="map-cluster-item__favorite__svg" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="-1 -1 24 23" preserveAspectRatio="none"><path class="st0" d="M15.7,0c-1.9,0-3.4,0.8-4.7,2.1C9.7,0.8,8.2,0,6.3,0C1,0-2.2,6.7,1.9,10.8c0.3,0.3,8.8,8.9,9.1,9.2 c0.3-0.3,8.8-8.9,9.1-9.2C24.2,6.7,21,0,15.7,0z"></path></svg>' +
            '				</div>\n' +
            '                <div class="map-cluster-item__img">\n' +
            '                </div>\n' +
            '                <div class="map-cluster-item__content">\n' +
            '                    <div class="map-cluster-item__content__description"></div>\n' +
            '                    <a href="" class="map-cluster-item__content__price"></a>\n' +
            '                    <div class="map-cluster-item__content__bottom"></div>\n' +
            '                    <div class="map-cluster-item__content__address"></div>\n' +
            '                </div>\n' +
            '            </div>';

        this.tmpl = '<div class="map-cluster">' +
            ' 				<div class="GMAPICustomOverlay__close_button">' +
            '				<svg class="map-cluster__close__svg" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 20 20">\n' +
            '                <path fill="#d1d1d1" fill-rule="evenodd" d="M11.505 9.899l8.052 8.052a1.084 1.084 0 1 1-1.533 1.534l-8.052-8.052-8.054 8.053a1.084 1.084 0 1 1-1.534-1.533l8.054-8.054L.384 1.846A1.085 1.085 0 1 1 1.918.313l8.053 8.053L18.023.314a1.084 1.084 0 1 1 1.534 1.534l-8.052 8.051z"></path>\n' +
            '            	</svg>' +
            '				</div>\n' +
            '<div class="map-cluster__top">' +
            '	<div class="map-cluster__top__city"></div>' +
            '	<div class="map-cluster__top__address"></div>' +
            '</div>' +
            '<div class="map-cluster__content"><div class="map-cluster__content__in"></div></div>' +
            '</div>';

        this.cluster = cluster;
        this.markers = cluster.getMarkers();
        this.json = allMarkers;
        this.initposition = this.markers[0].getPosition();
        this.map_ = map;
        this.html_ = null;
        this.div_ = null;


        this.renderTmpl();

    };

    function addFavoriteAdd(id, successCb = () => {
    }) {
        const formDataAdd = new FormData();

        formDataAdd.append('object_id', id);
        formDataAdd.append('type', 'POST');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataAdd
        })
            .then((res) => {
                successCb();
            })
            .catch((error) => console.log(error));
    }

    function addFavoriteRemove(id, successCb = () => {
    }) {
        const formDataRemove = new FormData();

        formDataRemove.append('object_id', id);
        formDataRemove.append('type', 'DELETE');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataRemove
        })
            .then((res) => {
                successCb();
            })
            .catch((error) => console.log(error));
    }


    ClusterInfo.prototype = OverlayViewPrototype;

    ClusterInfo.prototype.renderTmpl = function () {
        //
        let $html = this.tmpl;

        this.html_ = $html;

        let $self = this;

    };

    ClusterInfo.prototype.onAdd = function () {

        let div = document.createElement('div');
        div.style.position = 'absolute';
        div.classList.add('GMAPIClusterOverlay');
        let divClusterContainer = document.createElement('div'),
            divHeader = document.createElement('div');
        div.appendChild(divHeader);
        div.appendChild(divClusterContainer);
        divClusterContainer.classList.add('GMAPICustomOverlay__clusterContainer');
        divHeader.classList.add('GMAPICustomOverlay__clusterHeader');
        let divCloseButton = document.createElement('a');
        let divNumber = document.createElement('p');
        divNumber.classList.add('GMAPICustomOverlay__number');
        divCloseButton.classList.add('GMAPICustomOverlay__close_button');
        divCloseButton.innerHTML = '<svg width="36px" height="36px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M22.47375,19.94125 L36.98375,5.495 C37.67,4.81125 37.67375,3.705 36.99125,3.0175 C36.3075,2.32875 35.2025,2.3275 34.51625,3.01 L20.00125,17.45875 L5.61375,3.01625 C4.93375,2.3325 3.825,2.33 3.14,3.0125 C2.455,3.69625 2.45375,4.805 3.13625,5.49125 L17.52,19.93 L3.01625,34.3675 C2.33,35.05125 2.32625,36.1575 3.00875,36.845 C3.35,37.18875 3.8,37.36125 4.25,37.36125 C4.69625,37.36125 5.1425,37.19125 5.48375,36.85125 L19.99,22.41 L34.50875,36.98375 C34.85125,37.32875 35.3,37.5 35.74875,37.5 C36.19625,37.5 36.64375,37.32875 36.985,36.98875 C37.66875,36.305 37.67125,35.19875 36.98875,34.5125 L22.47375,19.94125 Z" id="Path"></path>\n' +
            '</svg>';
        divHeader.appendChild(divNumber);
        divHeader.appendChild(divCloseButton);

        this.markers.map((marker) => {
            allMarkers.forEach((item) => {
                if (marker.init_id === item.init_id) {
                    var panes = this.getPanes();
                    panes.floatPane.appendChild(div);
                    if (this.html_) {

                        let divName = document.createElement('div'),
                            divAdress = document.createElement('div'),
                            divPrice = document.createElement('div'),
                            divSquare = document.createElement('div'),
                            divButton = document.createElement('div'),
                            divContainer = document.createElement('a'),
                            divFavoriteIcon = document.createElement('div'),
                            divNumber = document.querySelector('.GMAPICustomOverlay__number'),
                            favoriteIcon = '        <span class="favorite__icon">\n' +
                                '            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
                                '                <g id="Icon/Bookmark/Normal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                                '                    <path class="book_border" d="M3.74999999,1.5 L14.25,1.5 C14.6642136,1.50000001 15,1.83578645 15,2.25000001 L15,15.75 C15.0000354,16.0173655 14.8577358,16.2645325 14.6265,16.39875 C14.3953995,16.5334634 14.1099062,16.5343216 13.878,16.401 L9,13.61325 L4.122,16.401 C3.88990771,16.5335749 3.60479866,16.5326116 3.37360757,16.3984713 C3.14241648,16.264331 3.00008621,16.0172881 3.00000001,15.75 L3.00000001,2.25000001 C3.00000001,1.83578645 3.33578644,1.50000001 3.74999999,1.5 Z M13.5,14.457 L13.5,3.00000001 L4.5,3.00000001 L4.5,14.457 L8.62799999,12.099 C8.85858869,11.9678434 9.14114735,11.9675586 9.37200001,12.09825 L13.5,14.457 Z" id="Combined-Shape" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                '                    <path class="book_inside" d="M14,15.5 L9.41333334,12.8235795 C9.15683039,12.6752869 8.84287632,12.6756101 8.58666666,12.8244305 L4,15.5 L4,2.5 L14,2.5 L14,15.5 Z" id="Path" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                '                </g>\n' +
                                '            </svg>\n' +
                                '        </span>';
                        divFavoriteIcon.classList.add('GMAPICustomOverlay__favorite', 'add-favorite');
                        divFavoriteIcon.innerHTML = favoriteIcon;
                        divNumber.innerHTML = this.markers.length + ' объявления';


                        divName.classList.add('GMAPICustomOverlay__name');
                        divName.innerHTML = item.title;
                        divAdress.classList.add('GMAPICustomOverlay__adress');
                        divAdress.innerHTML = item.address;
                        divPrice.classList.add('GMAPICustomOverlay__price');
                        divPrice.innerHTML = item.price;
                        divSquare.classList.add('GMAPICustomOverlay__square');
                        divSquare.innerHTML = item.price_per_meter + ' ₽ за м²';
                        divButton.classList.add('btn', 'btn_orange', 'GMAPICustomOverlay__button', 'callback-js');
                        divButton.innerHTML = '                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">\n' +
                            '                                    <path fill="#F4F4F8" fill-rule="evenodd"\n' +
                            '                                          d="M22.288 16.875L11.25 27.3l2.512 2.7L30 15.1 13.888 0 11.25 2.897l10.828 10.228H0v3.75z"/>\n' +
                            '                                </svg>';
                        divContainer.classList.add('GMAPICustomOverlay__container');

                        divButton.setAttribute('href', item.link);
                        divContainer.setAttribute('href', item.link);

                        this.div_ = div;

                        divClusterContainer.appendChild(divContainer);
                        divContainer.appendChild(divName);
                        divContainer.appendChild(divAdress);
                        divContainer.appendChild(divPrice);
                        divContainer.appendChild(divButton);
                        divContainer.appendChild(divFavoriteIcon);
                        divContainer.appendChild(divSquare);


                        divFavoriteIcon.setAttribute('data-map', marker.init_id);


                        let itemId = divFavoriteIcon.getAttribute('data-map');

                        divFavoriteIcon.addEventListener('click', function (e) {
                                e.preventDefault();
                                e.stopPropagation();

                                let favoriteNumber = document.querySelector('.favorite__number'),
                                    favoriteNumberId = favoriteNumber.getAttribute('data-id');

                                favoriteNumber.textContent = favoriteNumberId;

                                if (favoriteNumberId > 1) {

                                    let favoriteIcon = document.querySelector('.header-item__favorite');
                                    favoriteIcon.classList.add('header-item__favorited');

                                }
                                if (divFavoriteIcon.classList.contains('add-favorite')) {

                                    addFavoriteAdd(itemId, () => {

                                        favoriteNumber.setAttribute('data-id', (+favoriteNumberId + 1));
                                        favoriteNumber.innerHTML = (+favoriteNumberId + 1);
                                        divFavoriteIcon.classList.remove('add-favorite');
                                        divFavoriteIcon.classList.add('remove-favorite');
                                        divFavoriteIcon.classList.add('GMAPICustomOverlay__favorite_added')

                                    })
                                } else if (divFavoriteIcon.classList.contains('remove-favorite')) {
                                    addFavoriteRemove(itemId, () => {
                                        favoriteNumber.setAttribute('data-id', (+favoriteNumberId - 1));
                                        favoriteNumber.innerHTML = (+favoriteNumberId - 1);
                                        divFavoriteIcon.classList.add('add-favorite');
                                        divFavoriteIcon.classList.remove('remove-favorite');
                                        divFavoriteIcon.classList.remove('GMAPICustomOverlay__favorite_added')

                                    })
                                }
                            }
                        )

                    } else {
                        div.innerHTML = 'test'
                    }

                    divCloseButton.addEventListener('click', (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        this.div_.remove();
                    })
                }

            })
        })


        google.maps.event.clearInstanceListeners(this.div_);

        google.maps.event.addDomListener(this.div_, 'touchmove', this.stopPropagation_);
        google.maps.event.addDomListener(this.div_, 'touchstart', this.stopPropagation_);
        google.maps.event.addDomListener(this.div_, 'touchend', this.stopPropagation_);
        // google.maps.event.addDomListener(this.div_, 'mousewheel', this.stopPropagation_);
        // google.maps.event.addDomListener(this.div_, 'wheel', this.stopPropagation_);
        google.maps.event.addDomListener(this.div_, 'scroll', this.stopPropagation_);
        google.maps.event.addDomListener(this.div_, 'DOMMouseScroll', this.stopPropagation_);
    };

    ClusterInfo.prototype.stopPropagation_ = function (e) {
        e.stopPropagation();
    };

    ClusterInfo.prototype.draw = function () {

        this.position = this.getProjection().fromLatLngToDivPixel(this.initposition);

        let cHeight = this.div_.offsetHeight + 36 + 10,
            cWidth = this.div_.offsetWidth / 2;

        this.div_.style.top = this.position.y - cHeight + 'px';
        this.div_.style.left = this.position.x - cWidth + 'px';

        /* animation*/
        let pixelLatLng1 = this.getProjection().fromDivPixelToLatLng(new google.maps.Point(Math.round(this.position.x - cWidth - 10), Math.round(this.position.y - cHeight - 10)));
        let pixelLatLng2 = this.getProjection().fromDivPixelToLatLng(new google.maps.Point(Math.round(this.position.x + cWidth - 40), Math.round(this.position.y - 40)));

        let bounds = new google.maps.LatLngBounds();

        bounds.extend(pixelLatLng1);
        bounds.extend(pixelLatLng2);

        // this.map_.fitBounds(bounds);
        this.map_.panToBounds(bounds);

    };

    ClusterInfo.prototype.onRemove = function () {
        // if(clusterDiv !== null){
        //     clusterDiv.remove();
        // }
        this.div_.remove();
    };

    ClusterInfo.prototype.hide = function () {
        if (this.div_) {
            // The visibility property must be a string enclosed in quotes.
            this.div_.style.visibility = 'hidden';
        }
    };

    ClusterInfo.prototype.show = function () {
        if (this.div_) {
            this.div_.style.visibility = 'visible';
        }
    };

    ClusterInfo.prototype.toggle = function () {
        if (this.div_) {
            if (this.div_.style.visibility === 'hidden') {
                this.show();
            } else {
                this.hide();
            }
        }
    };

    ClusterInfo.prototype.removeMap = function () {
        if (this.getMap()) {
            this.setMap(null);
        }
    };

    ClusterInfo.prototype.returnMap = function () {
        this.setMap(this.map_);
    };

    return new ClusterInfo();
};

export default BuildClusterInfo;
