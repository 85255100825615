import Siema from "siema";
import Flickity from 'flickity';
import 'flickity-fullscreen';


export default function goodsSlider(){
    let goodSlider = document.getElementsByClassName('good-item-slider')[0];
    if(goodSlider !== undefined && goodSlider !== null){
        const prev = document.querySelector('.good-item-slider__prev');
        const next = document.querySelector('.good-item-slider__next');

        let flky = new Flickity ('.good-item-slider', {
            draggable: false,
            setGallerySize: false,
            resize: false,
            pageDots: false,
            fullscreen: true,
            freeScroll: true,
            wrapAround: true
        });

        let fullscreenBtn = document.querySelector('.carousel .flickity-fullscreen-button-view');
        fullscreenBtn.addEventListener('click', (e) => {
            let panel = document.querySelector('.good-print-panel');
            panel.classList.add('hidden');
        });
        let fullscreenExit = document.querySelector('.carousel .flickity-fullscreen-button-exit');
        fullscreenExit.addEventListener('click', (e) => {
            let panel = document.querySelector('.good-print-panel');
            panel.classList.remove('hidden');
        });
    }
    else {

    }


    let aboutSlider = document.getElementsByClassName('about-slider')[0];
    if(aboutSlider !== undefined && aboutSlider !== null){
        const mySiema = new Siema({
            selector: aboutSlider,
            duration: 200,
            easing: 'ease-out',
            startIndex: 0,
            draggable: false,
            multipleDrag: true,
            threshold: 20,
            loop: true,
            rtl: false,
            onInit: () => {},
            onChange: () => {},
        });
        const prev = document.querySelector('.about-slider__arrow_left');
        const next = document.querySelector('.about-slider__arrow_right');

        prev.addEventListener('click', () => mySiema.prev());
        next.addEventListener('click', () => mySiema.next());
    } else {
    }

    let projectSlider = document.querySelector('.project-slider');
    if(projectSlider !== null && projectSlider !== undefined){
        let flky = new Flickity ('.project-slider', {
            draggable: false,
            setGallerySize: false,
            resize: false,
            pageDots: true,
            fullscreen: true,
            freeScroll: true,
            wrapAround: true
        });

}}