import favorite from './favorite';

export default function requestObjects(){

    let uploadObjectBtn = document.querySelector('.upload-object-js'),
        offset = 5;

    if(uploadObjectBtn){
        let employerObj = document.querySelector('.employer-work'),
            employerContiner = document.querySelector('.employer-work-container'),
            employerId = employerObj.getAttribute('data-employer');

        uploadObjectBtn.addEventListener('click', (e) => {
            let limit = 5;
            e.preventDefault();
            fetch(`/catalog/related-objects-employer?employer=${employerId}&limit=${limit}&offset=${offset}` , {
                method: 'GET',
            })
                .then(response =>  response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    offset = offset + 5;
                    obj.body.data.forEach(item => {
                        let createObj = document.createElement('div');
                        createObj.className = "goods-item-container good-state";
                        createObj.setAttribute('data-lang', item.location.latitude);
                        createObj.setAttribute('data-long', item.location.longitude);
                        createObj.setAttribute('data-map', item.id);
                        let goodItem = document.createElement('a');
                        goodItem.setAttribute('href', item.link);
                        goodItem.classList.add('goods-item');

                        let favoriteIcon,
                            favoriteText;

                        if(item.favorite_added === false){
                            favoriteIcon = '<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg"\n' +
                                'xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
                                '<g id="Icon/Bookmark/Normal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                                '<path class="book_border"\n' +
                                'd="M3.74999999,1.5 L14.25,1.5 C14.6642136,1.50000001 15,1.83578645 15,2.25000001 L15,15.75 C15.0000354,16.0173655 14.8577358,16.2645325 14.6265,16.39875 C14.3953995,16.5334634 14.1099062,16.5343216 13.878,16.401 L9,13.61325 L4.122,16.401 C3.88990771,16.5335749 3.60479866,16.5326116 3.37360757,16.3984713 C3.14241648,16.264331 3.00008621,16.0172881 3.00000001,15.75 L3.00000001,2.25000001 C3.00000001,1.83578645 3.33578644,1.50000001 3.74999999,1.5 Z M13.5,14.457 L13.5,3.00000001 L4.5,3.00000001 L4.5,14.457 L8.62799999,12.099 C8.85858869,11.9678434 9.14114735,11.9675586 9.37200001,12.09825 L13.5,14.457 Z"\n' +
                                'id="Combined-Shape" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                '<path class="book_inside"\n' +
                                'd="M14,15.5 L9.41333334,12.8235795 C9.15683039,12.6752869 8.84287632,12.6756101 8.58666666,12.8244305 L4,15.5 L4,2.5 L14,2.5 L14,15.5 Z"\n' +
                                'id="Path" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                '</g>\n' +
                                '</svg>\n';
                            favoriteText = 'Добавить в избранное';
                        } else {
                            favoriteIcon =                            '                    <svg class="active" width="16px" height="16px" viewBox="0 0 16 16" version="1.1"\n' +
                                '                         xmlns="http://www.w3.org/2000/svg">\n' +
                                '                        <g id="Icon/Bookmark/Normal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                                '                            <path class="book_border"\n' +
                                '                                  d="M3.74999999,1.5 L14.25,1.5 C14.6642136,1.50000001 15,1.83578645 15,2.25000001 L15,15.75 C15.0000354,16.0173655 14.8577358,16.2645325 14.6265,16.39875 C14.3953995,16.5334634 14.1099062,16.5343216 13.878,16.401 L9,13.61325 L4.122,16.401 C3.88990771,16.5335749 3.60479866,16.5326116 3.37360757,16.3984713 C3.14241648,16.264331 3.00008621,16.0172881 3.00000001,15.75 L3.00000001,2.25000001 C3.00000001,1.83578645 3.33578644,1.50000001 3.74999999,1.5 Z M13.5,14.457 L13.5,3.00000001 L4.5,3.00000001 L4.5,14.457 L8.62799999,12.099 C8.85858869,11.9678434 9.14114735,11.9675586 9.37200001,12.09825 L13.5,14.457 Z"\n' +
                                '                                  id="Combined-Shape" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                '                            <path class="book_inside"\n' +
                                '                                  d="M14,15.5 L9.41333334,12.8235795 C9.15683039,12.6752869 8.84287632,12.6756101 8.58666666,12.8244305 L4,15.5 L4,2.5 L14,2.5 L14,15.5 Z"\n' +
                                '                                  id="Path" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                '                        </g>\n' +
                                '                    </svg>\n';
                            favoriteText = 'Удалить из избранного'
                        }


                        goodItem.innerHTML =
                            '        <div class="goods-item-img">\n' +
                            `        <img src=${item.image} height="260px">` +
                            '        </div>\n' +
                            '        <div class="goods-item-description">\n' +
                            `         <div class="goods-item-description__price sm-show lg-hidden md-hidden">${item.price}` +
                            '            </div>\n' +
                            '            <div>\n' +
                            `                <h4>${item.title}</h4> ` +
                            '            </div>\n' +
                            `             <div class="goods-item-description__floor">${item.floor} этаж</div>` +
                            `            <div class="goods-item-description__adress">${item.city} + ',' + ${item.address || ''}</div>` +
                            `            <div class="goods-item-description__text">${item.description}</div>` +
                            `            <div class="goods-item-description__favorite favorite-js">` +
                            '            <span class="favorite__icon">\n' +
                            `${favoriteIcon} </span>` +
                            `${favoriteText}` +
                            '                </div>\n' +
                            '            <div class="goods-item-description__favorite__book add-favorite">\n' +
                            '                <span class="favorite__icon">\n' +
                            '                    <svg viewBox="0 0 24 24">\n' +
                            '                    <path xmlns="http://www.w3.org/2000/svg"\n' +
                            '                          d="M4.99999999,1.99999999 C4.44771525,2.00000001 4.00000001,2.44771526 4.00000001,3 L4.00000001,21 C4.00011494,21.3563841 4.18988863,21.6857747 4.49814343,21.8646284 C4.80639822,22.0434821 5.18654361,22.0447666 5.496,21.868 L12,18.151 L18.504,21.868 C18.8132082,22.0457622 19.1938661,22.0446179 19.502,21.865 C19.8103144,21.6860433 20.0000472,21.3564874 20,21 L20,3 C20,2.44771526 19.5522848,2.00000001 19,1.99999999 L4.99999999,1.99999999 Z M18,19.276 L12.496,16.131 C12.1881965,15.9567448 11.8114516,15.9571246 11.504,16.132 L6,19.276 L6,4.00000001 L18,4.00000001 L18,19.276 Z"\n' +
                            '                          id="Shape" fill-rule="nonzero"/>\n' +
                            '                    </svg>\n' +
                            '                </span>\n' +
                            '            </div>\n' +
                            '            <div class="goods-item-description__characteristics lg-hidden">\n' +
                            '                <div>\n' +
                            '                    <div class="goods-item-description__price sm-hidden">\n' +
                            '                    </div>\n' +
                            `                    <div class="goods-item-description__width sm-hidden">${item.price_per_meter}` +
                            '                        ₽ за м²\n' +
                            '                    </div>\n' +
                            '                </div>\n' +
                            `               <div  class="goods-item-description__btn btn btn_orange">` +
                            '                   Подробнее\n' +
                            '                    </div>\n' +
                            '            </div>\n' +
                            '        </div></div>\n' +
                            '        <div class="goods-item-description goods-item-description_right md-hidden">\n' +
                            `            <div class="goods-item-description__price">${item.price}</div>` +
                            `            <div class="goods-item-description__width">${item.price_per_meter} ₽ за м²</div>` +
                            '            <div class="goods-item-description__phone">\n' +
                            '                    <span class="callback__icon">\n' +
                            '                        <svg viewBox="4 6 14 14">\n' +
                            '                            <path xmlns="http://www.w3.org/2000/svg"\n' +
                            '                                  d="M17.6652843,7.0706181 C16.9671457,5.58524759 16.3620922,5.1210693 15.943209,5.02823365 C15.617411,4.93539799 15.2450704,5.07465148 14.8727298,5.39957627 C14.5469317,5.67808325 12.9179416,7.0706181 12.9179416,8.13822815 C12.9179416,8.74165992 13.1506544,8.92733123 13.4299099,9.25225603 C13.6160802,9.43792735 13.8022505,9.67001649 13.9418782,9.94852346 C14.0349634,10.2270304 14.1280485,10.5055374 14.1280485,10.9232979 C14.081506,12.8728466 11.8474623,15.1009024 9.89267412,15.1473202 L9.84613154,15.1473202 C9.47379093,15.1473202 9.1479929,15.0544846 8.91528002,14.9616489 C8.58948199,14.8223954 8.40331168,14.6367241 8.21714138,14.4510528 C7.93788592,14.1725458 7.70517304,13.9404567 7.10011955,13.9404567 C6.0296403,13.9404567 4.63336301,15.5650807 4.40065013,15.8900055 C4.0748521,16.2613481 3.93522437,16.6326908 4.02830953,16.9576155 C4.12139468,17.375376 4.54027786,17.9788078 6.07618287,18.6750752 C6.58815121,18.9071643 7.14666213,19 7.79825819,19 L7.89134334,19 C13.2902822,18.8607465 17.9445398,14.2653815 17.9910824,8.88091341 C18.0376249,8.18464598 17.8979972,7.58121421 17.6652843,7.0706181 Z"\n' +
                            '                                  id="Path" fill="#FB7E24"/>\n' +
                            '                        </svg>\n' +
                            '                    </span>\n' +
                            `                    ${item.specialist_phone}` +
                            '            </div>\n' +
                            '            <div class="goods-item-description__btn btn btn_grey">\n' +
                            '                <span class="geolocation__icon">\n' +
                            '                    <svg viewBox="0 0 20 20">\n' +
                            '                        <path xmlns="http://www.w3.org/2000/svg"\n' +
                            '                              d="M9.00527344,1.08632813 C5.40351562,1.08632813 2.48378906,3.84257813 2.48378906,7.2421875 C2.48378906,9.90175781 6.47578125,14.8728516 8.2125,16.5533203 C8.64667969,16.9734375 9.36035156,16.9734375 9.79453125,16.5533203 C11.53125,14.8728516 15.5232422,9.90351562 15.5232422,7.2421875 C15.525,3.84257813 12.6070312,1.08632813 9.00527344,1.08632813 Z M9.00527344,9.49921875 C7.68515625,9.49921875 6.61464844,8.48847656 6.61464844,7.2421875 C6.61464844,5.99589844 7.68515625,4.98515625 9.00527344,4.98515625 C10.3253906,4.98515625 11.3958984,5.99589844 11.3958984,7.2421875 C11.3958984,8.48847656 10.3253906,9.49921875 9.00527344,9.49921875 Z"\n' +
                            '                              id="Shape" fill="#FB7E24" fill-rule="nonzero"/>\n' +
                            '                    </svg>\n' +
                            '                </span>Показать на карте\n' +
                            '            </div>\n' +
                            '        </div>';

                        let favoriteField = goodItem.querySelector('.goods-item-description__favorite');

                        if(item.favorite_added){
                            favoriteField.classList.add('remove-favorite');
                        } else {
                            favoriteField.classList.add('add-favorite');
                        }

                            createObj.appendChild(goodItem);

                        employerContiner.insertBefore(createObj ,uploadObjectBtn);

                    });

                    if(obj.body.end === true){
                        uploadObjectBtn.remove();
                    }

                    favorite();

                    document.querySelectorAll('.goods-item-container').forEach(function (item) {
                        let mapBtn = item.querySelector('.goods-item-description__btn.btn.btn_grey'),
                            open = false,
                            mapId = item.getAttribute('data-map'),
                            itemCordsLong = item.getAttribute('data-long'),
                            itemCordsLang = item.getAttribute('data-lang');

                        let toggleMap = (needOpen = true) => {
                            if (needOpen) {
                                let googleMap = document.createElement('div');
                                item.appendChild(googleMap);
                                googleMap.setAttribute("id", mapId);
                                googleMap.classList.add('goods-map');
                            } else {
                                let googleMap = item.querySelector('.goods-map');
                                googleMap.remove();
                            }
                            open = needOpen;
                        };

                        if(mapBtn !== null){


                            mapBtn.addEventListener('click', (e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                toggleMap(!open);

                                let map, infowindow, marker;

                                map = new google.maps.Map(document.getElementById(mapId), {
                                    center: {
                                        "lat": 55.1853034,
                                        "lng": 61.3222833
                                    },
                                    zoom: 14,
                                    fullscreenControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    scrollwheel: false,
                                    clickableIcons: false,
                                });


                                marker = new google.maps.Marker({
                                    position: {
                                        "lat": +itemCordsLang,
                                        "lng": +itemCordsLong
                                    },
                                    map: map,
                                    icon: '/static/assets/img/icons/map/icon-location.svg'
                                });

                                var bounds = new google.maps.LatLngBounds();

                                bounds.extend(marker.position);

                                map.fitBounds(bounds);
                                let listener = google.maps.event.addListener(map, "idle", function () {
                                    if (map.getZoom() > 16) map.setZoom(16);
                                    google.maps.event.removeListener(listener);
                                });
                            })
                        }
                    })
                    })
                });

        }
    }