export default function requestArticle(){

    let uploadArticleBtn = document.querySelector('.upload-article-js'),
        offset = 7;

    if(uploadArticleBtn){
        let articlesContiner = document.querySelector('.articles-container');

        uploadArticleBtn.addEventListener('click', (e) => {
            let limit = 7;
            e.preventDefault();
            fetch(`/blog/get-articles?limit=${limit}&offset=${offset}` , {
                method: 'GET',
            })
                .then(response =>  response.json().then(data => ({status: response.status, body: data})))
                .then(obj => {
                    offset = offset + 7;
                    obj.body.data.forEach((item, index) => {
                        let createObj = document.createElement('a');
                        createObj.setAttribute('href', item.link);
                        if(index !== 4){
                            createObj.className = "articles-item col--lg-1/2 col--md-12 col--sm-12";
                            createObj.innerHTML = `
                            <div style="background-image: url(${item.image});" class="articles-item__picture">
                                <div class="articles-item__title">${item.title}</div>
                                <div class="articles-item__date">${item.date}</div>
                            </div>`
                        } else {
                            createObj.className = "journal-item articles-item__expert col--lg-12 col--md-12 col--sm-12";
                            createObj.innerHTML = `
                                <div class="journal-item journal-item-expert">
                                    <div class="journal-item-expert_left">
                                        <div class="journal-item-expert__mark">
                                                <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <path fill="#6D7C85" fill-rule="nonzero" d="M1.333 15.5h13.334c.46 0 .833-.373.833-.833V1.333a.835.835 0 0 0-1.484-.52l-2.683 3.353L8.651.812c-.318-.395-.985-.395-1.303 0L4.667 4.167 1.984.812A.835.835 0 0 0 .5 1.333v13.334a.833.833 0 0 0 .833.833zm.834-1.667v-1.666h11.666v1.666H2.167zm1.849-7.812c.317.396.985.396 1.302 0L8 2.668l2.682 3.353c.318.396.986.396 1.303 0l1.848-2.312V10.5H2.167V3.71l1.849 2.31z"></path>
                                                    </svg>
                                                </span>
                                            Мнение эксперта
                                        </div>
                                        <div class="journal-item-expert__title">
                                            <div href="/blog/articles/test/">${item.title}</div><br>
                                        </div>
                                    </div>
                                    <div itemscope="" itemtype="http://schema.org/ImageObject" class="good-item-contacts__broker">
                                        
                                        <div itemprop="name" class="good-item-contacts__broker__name">
                                             <br>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>`
                        }

                        // articlesContiner.appendChild(createObj);

                        articlesContiner.insertBefore(createObj ,uploadArticleBtn.nextSibling);

                    });

                    if(obj.body.end === true){
                        uploadArticleBtn.remove();
                    }

                })
        });

    }
}