export default function settingsPanel(){
    let settingPanelCall = document.querySelector('.setting-panel-call'),
        open = false;

    if (settingPanelCall !== undefined && settingPanelCall !== null) {
    let settingPanel = document.querySelector('.setting-panel');

    document.addEventListener('click', (e) => {
        if(open === true && e.target !== settingPanelCall) {
            settingPanel.classList.remove('setting-panel_active');
        }
    });

    settingPanelCall.addEventListener('click', (e) => {
        open = true;
        settingPanel.classList.toggle('setting-panel_active');
    })
    }

}