// import 'js-marker-clusterer';
import ClusterInfo from './ClusterInfo';
import 'markerclustererplus-node';

let GoogleMapsLoader = require('google-maps');

export default function map() {

        function addFavoriteAdd(id, successCb = () => {}){
        const formDataAdd = new FormData();

        formDataAdd.append('object_id', id);
        formDataAdd.append('type', 'POST');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataAdd
        })
        .then((res) => {
            successCb();
        })
    }

    function addFavoriteRemove(id, successCb = () => {}){
        const formDataRemove = new FormData();

        formDataRemove.append('object_id', id);
        formDataRemove.append('type', 'DELETE');

        fetch('/catalog/add-object-in-favorite', {
            method: 'POST',
            body: formDataRemove
        })
        .then((res) => {
            successCb();
        })
    }

    GoogleMapsLoader.KEY = 'AIzaSyBZ7OdGB0_1z52sY4GS6rLA8jeyXTT9rUs';

    window.addEventListener('load', () => {

        GoogleMapsLoader.load((google => {

            document.querySelectorAll('.goods-item-container').forEach(function (item) {
                let mapBtn = item.querySelector('.goods-item-description__btn.btn.btn_grey'),
                    open = false,
                    mapId = item.getAttribute('data-map'),
                    itemCordsLong = item.getAttribute('data-long'),
                    itemCordsLang = item.getAttribute('data-lang');

                let toggleMap = (needOpen = true) => {
                    if (needOpen) {
                        let googleMap = document.createElement('div');
                        item.appendChild(googleMap);
                        googleMap.setAttribute("id", mapId);
                        googleMap.classList.add('goods-map');
                    } else {
                        let googleMap = item.querySelector('.goods-map');
                        googleMap.remove();
                    }
                    open = needOpen;
                };

                if(mapBtn !== null){


                mapBtn.addEventListener('click', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleMap(!open);

                    let map, infowindow, marker;

                    map = new google.maps.Map(document.getElementById(mapId), {
                        center: {
                            "lat": 55.1853034,
                            "lng": 61.3222833
                        },
                        zoom: 14,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        scrollwheel: false,
                        clickableIcons: false,
                    });


                    marker = new google.maps.Marker({
                        position: {
                            "lat": +itemCordsLang,
                            "lng": +itemCordsLong
                        },
                        map: map,
                        icon: '/static/assets/img/icons/map/icon-location.svg'
                    });

                    var bounds = new google.maps.LatLngBounds();

                    bounds.extend(marker.position);

                    map.fitBounds(bounds);
                    let listener = google.maps.event.addListener(map, "idle", function () {
                        if (map.getZoom() > 16) map.setZoom(16);
                        google.maps.event.removeListener(listener);
                    });
                })
                    }
            });

            document.querySelectorAll('.init-map-js').forEach((item) => {
                let map,
                    marker,

                    itemCordsLong = item.getAttribute('data-long'),
                    itemCordsLang = item.getAttribute('data-lang'),
                    icon = '\n' +
                        '<svg xmlns="http://www.w3.org/2000/svg" width="48" height="60" viewBox="0 0 48 60">\n' +
                        '    <path fill="#FB7E24" fill-rule="nonzero" d="M40.97 7.29c-9.372-9.72-24.568-9.72-33.94 0-9.373 9.723-9.373 25.486 0 35.207L24 60l16.97-17.503c9.373-9.72 9.373-25.484 0-35.207zM24 31.5a7.503 7.503 0 0 1-7.5-7.501 7.501 7.501 0 1 1 7.5 7.5z"/>\n' +
                        '</svg>\n';


                map = new google.maps.Map(item, {
                    center: {
                        "lat": 55.1853034,
                        "lng": 61.3222833
                    },
                    zoom: 7,
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    scrollwheel: false,
                    clickableIcons: false,
                });

                marker = new google.maps.Marker({
                    position: {
                        "lat": +itemCordsLang,
                        "lng": +itemCordsLong
                    },
                    map: map,
                    icon: '/static/assets/img/icons/map/icon-location.svg'
                });

                var bounds = new google.maps.LatLngBounds();

                bounds.extend(marker.position);

                map.fitBounds(bounds);
                let listener = google.maps.event.addListener(map, "idle", function () {
                    if (map.getZoom() > 16) map.setZoom(16);
                    google.maps.event.removeListener(listener);
                });
            });

            let map,
                marker,
                popup,
                Popup,
                openOverlay = false,
                openCluster = false,
                showFilter = document.querySelector('.show-search'),
                clusterMarkers = [],
                clusterMarkersUnchecked = [],
                clusterMarkersChecked = [],
                allMarkers = [],
                areaCoords = [],
                activeOverlay = null,
                iconMarker = '<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><defs><circle id="path-1" cx="10" cy="10" r="10"/><filter x="-40%" y="-30%" width="180%" height="180%" filterUnits="objectBoundingBox" id="filter-2"><feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/><feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.304432745 0" in="shadowBlurOuter1"/></filter></defs><g id="Desktop-1920" fill="none" fill-rule="evenodd"><g id="На-карте_1920" transform="translate(-945.000000, -675.000000)" fill-rule="nonzero"><g id="object_dots" transform="translate(340.000000, 275.000000)"><g id="Dot/Single" transform="translate(610.000000, 403.000000)"><g id="Oval"><use fill="black" filter="url(#filter-2)" xlink:href="#path-1"/><use stroke="#FFFFFF" stroke-width="2" fill="#FB7E24" xlink:href="#path-1"/></g></g></g></g></g></svg>',
                iconMarkerChecked = '\n' +
                    '<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
                    '    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                    '        <g id="Dot/Single" transform="translate(1.000000, 1.000000)" fill="#FFFFFF" fill-rule="nonzero" stroke="#6D7C85" stroke-width="2">\n' +
                    '            <circle id="Oval" cx="10" cy="10" r="10"></circle>\n' +
                    '        </g>\n' +
                    '    </g>\n' +
                    '</svg>';


            if(showFilter !== null){
                showFilter.addEventListener('click', (e) => {
                    e.preventDefault();
                    let searchPanel = document.querySelector('.search');
                    searchPanel.classList.add('search_show');
                    showFilter.remove();
                })
            }

            let getMap = document.querySelector('#map');

            if (getMap !== undefined && getMap !== null) {

                let getMapJSON = getMap.getAttribute('data-json-map');

                let allMarkersJson = JSON.parse(getMapJSON);

                let open = false;

                const MAXZOOM = 18;

                let markerCenter = (marker, map) => {
                    if (map.getZoom() < 8) {
                        map.setCenter(marker.getPosition());
                        map.setZoom(11);
                    }
                };

                // инит карты
                map = new google.maps.Map(document.getElementById('map'), {
                    center: {
                        lat: 55.1853034,
                        lng: 61.3222833
                    },
                    zoom: 14,
                    mapTypeControl: false,
                    gestureHandling: 'greedy',
                    disableDefaultUI: true
                });

                let bounds = new google.maps.LatLngBounds();

                allMarkersJson.forEach(function (item) {
                    let coordsLatitude = item.location.latitude;
                    let coordslongitude = item.location.longitude;
                    let coords = item.location;

                    let id = item.id;

                    let markerIcon;


                    if(item.viewed === false){
                        markerIcon = {url: '/static/assets/img/icons/map/single.svg'}
                    } else {
                        markerIcon = {url: '/static/assets/img/icons/map/single_visited.svg'}
                    }

                    marker = new google.maps.Marker({
                        position: {
                            lat: +coordsLatitude,
                            lng: +coordslongitude
                        },
                        icon : markerIcon,
                        optimized: false,
                        zIndex: 0,
                        map: map,
                        address: item.address,
                        title: item.title,
                        price: item.price,
                        link: item.link,
                        price_per_meter: item.price_per_meter,
                        viewed: item.viewed
                    });

                    let loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

                    bounds.extend(loc);

                    clusterMarkers.push(marker);

                    if(item.viewed === true){
                        clusterMarkersChecked.push(marker);
                    }

                    if(item.viewed === false){
                        clusterMarkersUnchecked.push(marker);
                    }

                    allMarkers[id] = marker;

                    marker['init_id'] = id;


                    google.maps.event.addListener(marker, "click", function () {
                        map.setCenter(this.getPosition());

                    });


                    let createCustomOverlay = function () {
                        let overlay;

                        CustomOverlay.prototype = new google.maps.OverlayView();

                        let bounds = new google.maps.LatLngBounds(

                            new google.maps.LatLng(+coordsLatitude, +coordslongitude),

                        );

                        overlay = new CustomOverlay(bounds, map);

                        function CustomOverlay(bounds, map) {

                            this.bounds_ = bounds;

                            this.map_ = map;

                            this.div_ = null;

                            this.setMap(map);
                        }

                        CustomOverlay.prototype.onAdd = function () {

                            let div = document.createElement('a');
                            div.classList.add('GMAPICustomOverlay');
                            div.style.position = 'absolute';

                            let divName = document.createElement('div'),
                                divAdress = document.createElement('div'),
                                divPrice = document.createElement('div'),
                                divSquare = document.createElement('div'),
                                divButton = document.createElement('div'),
                                divCloseButton = document.createElement('div'),
                                divFavoriteIcon = document.createElement('div'),
                                favoriteIcon = '        <span class="favorite__icon">\n' +
                                    '            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
                                    '                <g id="Icon/Bookmark/Normal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\n' +
                                    '                    <path class="book_border" d="M3.74999999,1.5 L14.25,1.5 C14.6642136,1.50000001 15,1.83578645 15,2.25000001 L15,15.75 C15.0000354,16.0173655 14.8577358,16.2645325 14.6265,16.39875 C14.3953995,16.5334634 14.1099062,16.5343216 13.878,16.401 L9,13.61325 L4.122,16.401 C3.88990771,16.5335749 3.60479866,16.5326116 3.37360757,16.3984713 C3.14241648,16.264331 3.00008621,16.0172881 3.00000001,15.75 L3.00000001,2.25000001 C3.00000001,1.83578645 3.33578644,1.50000001 3.74999999,1.5 Z M13.5,14.457 L13.5,3.00000001 L4.5,3.00000001 L4.5,14.457 L8.62799999,12.099 C8.85858869,11.9678434 9.14114735,11.9675586 9.37200001,12.09825 L13.5,14.457 Z" id="Combined-Shape" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                    '                    <path class="book_inside" d="M14,15.5 L9.41333334,12.8235795 C9.15683039,12.6752869 8.84287632,12.6756101 8.58666666,12.8244305 L4,15.5 L4,2.5 L14,2.5 L14,15.5 Z" id="Path" fill="#6D7C85" fill-rule="nonzero"></path>\n' +
                                    '                </g>\n' +
                                    '            </svg>\n' +
                                    '        </span>';

                            divFavoriteIcon.innerHTML = favoriteIcon;

                            divName.classList.add('GMAPICustomOverlay__name');
                            divName.innerHTML = item.title;
                            divAdress.classList.add('GMAPICustomOverlay__adress');
                            divAdress.innerHTML = item.address;
                            divPrice.classList.add('GMAPICustomOverlay__price');
                            divPrice.innerHTML = item.price;
                            divSquare.classList.add('GMAPICustomOverlay__square');
                            divSquare.innerHTML = item.price_per_meter + ' ₽ за м²';
                            divButton.classList.add('btn', 'btn_orange', 'GMAPICustomOverlay__button');
                            divButton.setAttribute('href', item.link);
                            divButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">\n' +
                                '<path fill="#F4F4F8" fill-rule="evenodd"\n' +
                                'd="M22.288 16.875L11.25 27.3l2.512 2.7L30 15.1 13.888 0 11.25 2.897l10.828 10.228H0v3.75z"/>\n' +
                                '</svg>';
                            divCloseButton.classList.add('GMAPICustomOverlay__close_button');
                            divCloseButton.innerHTML = '<svg width="36px" height="36px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
                                '<path d="M22.47375,19.94125 L36.98375,5.495 C37.67,4.81125 37.67375,3.705 36.99125,3.0175 C36.3075,2.32875 35.2025,2.3275 34.51625,3.01 L20.00125,17.45875 L5.61375,3.01625 C4.93375,2.3325 3.825,2.33 3.14,3.0125 C2.455,3.69625 2.45375,4.805 3.13625,5.49125 L17.52,19.93 L3.01625,34.3675 C2.33,35.05125 2.32625,36.1575 3.00875,36.845 C3.35,37.18875 3.8,37.36125 4.25,37.36125 C4.69625,37.36125 5.1425,37.19125 5.48375,36.85125 L19.99,22.41 L34.50875,36.98375 C34.85125,37.32875 35.3,37.5 35.74875,37.5 C36.19625,37.5 36.64375,37.32875 36.985,36.98875 C37.66875,36.305 37.67125,35.19875 36.98875,34.5125 L22.47375,19.94125 Z" id="Path"></path>\n' +
                                '</svg>'

                            divFavoriteIcon.classList.add('GMAPICustomOverlay__favorite', 'add-favorite');
                            div.setAttribute('href', item.link);

                            if(item.favorite_added === true){
                               divFavoriteIcon.classList.add('added-favorite');
                            }


                            this.div_ = div;

                            var panes = this.getPanes();

                            div.appendChild(divName);
                            div.appendChild(divAdress);
                            div.appendChild(divPrice);
                            div.appendChild(divButton);
                            div.appendChild(divCloseButton);
                            div.appendChild(divFavoriteIcon);
                            div.appendChild(divSquare);


                            divFavoriteIcon.addEventListener('click', (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                            let favoriteNumber = document.querySelector('.favorite__number'),
                                favoriteNumberId = favoriteNumber.getAttribute('data-id');
                            if (divFavoriteIcon.classList.contains('add-favorite')) {

                            addFavoriteAdd(item.id, ()=> {
                                favoriteNumber.setAttribute('data-id', (+favoriteNumberId + 1));
                                favoriteNumber.innerHTML = (+favoriteNumberId + 1);
                                divFavoriteIcon.classList.remove('add-favorite');
                                divFavoriteIcon.classList.add('remove-favorite');
                                divFavoriteIcon.classList.add('added-favorite');
                            });

                            }

                            if (divFavoriteIcon.classList.contains('remove-favorite')) {

                                addFavoriteRemove(item.id, ()=> {

                                    favoriteNumber.setAttribute('data-id', (+favoriteNumberId - 1));
                                    favoriteNumber.innerHTML = (+favoriteNumberId - 1);
                                    divFavoriteIcon.classList.remove('remove-favorite');
                                    divFavoriteIcon.classList.add('add-favorite');
                                    divFavoriteIcon.classList.remove('added-favorite');

                                })
                            }
                            });


                            this.getPanes().floatPane.appendChild(div);

                            divCloseButton.addEventListener('click', (e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                div.remove();
                            });

                            google.maps.event.clearInstanceListeners(div);

                            google.maps.event.addDomListener(div, 'touchmove', this.stopPropagation_);
                            google.maps.event.addDomListener(div, 'touchstart', this.stopPropagation_);
                            google.maps.event.addDomListener(div, 'touchend', this.stopPropagation_);
                            google.maps.event.addDomListener(div, 'mousewheel', this.stopPropagation_);
                            google.maps.event.addDomListener(div, 'wheel', this.stopPropagation_);
                            google.maps.event.addDomListener(div, 'scroll', this.stopPropagation_);
                            google.maps.event.addDomListener(div, 'DOMMouseScroll', this.stopPropagation_);

                        };

                        CustomOverlay.prototype.draw = function () {

                            let overlayProjection = this.getProjection();

                            let sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());

                            let ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

                            // Resize the image's div to fit the indicated dimensions.

                            let div = this.div_;

                            if (window.matchMedia("(max-width: 576px)").matches) {
                                div.style.left = (sw.x - 149) + 'px';
                                div.style.top = (ne.y - 210) + 'px';
                            } else {
                                div.style.left = (sw.x - 200) + 'px';
                                div.style.top = (ne.y - 240) + 'px';
                            }
                        };

                        CustomOverlay.prototype.onRemove = function () {
                            this.div_.remove();
                            // this.div_ = null;
                        };

                        CustomOverlay.prototype.hide = function () {
                            if (this.div_) {
                                // The visibility property must be a string enclosed in quotes.
                                this.div_.style.visibility = 'hidden';
                            }
                        };

                        CustomOverlay.prototype.show = function () {
                            if (this.div_) {
                                this.div_.style.visibility = 'visible';
                            }
                        };

                        CustomOverlay.prototype.toggle = function () {
                            if (this.div_) {
                                if (this.div_.style.visibility === 'hidden') {
                                    this.show();
                                } else {
                                    this.hide();
                                }
                            }
                        };

                        CustomOverlay.prototype.removeMap = function () {
                            if (this.getMap()) {
                                this.setMap(null);
                            }
                        };

                        CustomOverlay.prototype.returnMap = function () {
                            this.setMap(this.map_);
                        };

                    };

                    marker.addListener('click', (e) => {
                        let clusterOverlay = document.querySelector('.GMAPIClusterOverlay');
                        if(clusterOverlay !== null){
                            clusterOverlay.remove();
                        }
                        let customOverlay = document.querySelector('.GMAPICustomOverlay');
                        if(customOverlay !== null){
                            customOverlay.remove();
                            createCustomOverlay()
                        } else {
                            createCustomOverlay()
                        }

                    })
                });

                let showClusterInfo = (cluster) => {

				let overlay = ClusterInfo(cluster, allMarkers, map, new google.maps.OverlayView());

				if (activeOverlay && activeOverlay.cluster === overlay.cluster){
					activeOverlay.removeMap();
					activeOverlay = null;
				} else {
					if (activeOverlay) {
						activeOverlay.removeMap();
						activeOverlay = null;
					}
					overlay.returnMap();
					activeOverlay = overlay;
				}

                };

                let clusterImg = '/static/assets/img/icons/map/map-cluster1.svg',
                    clusterImgChecked = '/static/assets/img/icons/map/cluster_visited.svg';

                let markerCluster = new MarkerClusterer(map, clusterMarkersUnchecked, {
                    gridSize: 50,
                    zoomOnClick: false,
                    averageCenter: true,
                    cssClass: 'test',
                    styles: [{
                        url: clusterImg,
                        width: 40,
                        height: 40,
                        textSize: 16,
                        textColor: '#fff'
                    }
                    ],
                });

                let markerClusterChecked = new MarkerClusterer(map, clusterMarkersChecked, {
                    gridSize: 50,
                    zoomOnClick: false,
                    averageCenter: true,
                    cssClass: 'test',
                    styles: [{
                        url: clusterImgChecked,
                        width: 40,
                        height: 40,
                        textSize: 16,
                        textColor: '#6D7C85'
                    }
                    ],
                });

                let checkCoordsInOnePoint = (cluster) => {

                    let checkMarkers = cluster.getMarkers(),
                        checkCords = false,
                        result = true;

                    checkMarkers.forEach((item, id) => {

                        let lt = item.position.lat(),
                            lg = item.position.lng();

                        if (!result) return;

                        if (!checkCords) {
                            checkCords = {lt: lt, lg: lg};
                        } else {
                            if ((checkCords.lt !== lt) || (checkCords.lg !== lg)) {
                                result = false;
                            }
                        }

                    });

                    return result;
                };

                let clusterOverlay = document.querySelector('.GMAPIClusterOverlay');
                if(clusterOverlay !== null){
                    let closeClusterOverlay = clusterOverlay.querySelector('.GMAPICustomOverlay__close_button');
                    closeClusterOverlay.addEventListener('click', (e) => {
                        e.preventDefault();
                        if (activeOverlay && activeOverlay.cluster){
                            activeOverlay.setMap(null);
                            activeOverlay = false;
                        }
                    })
                }


                google.maps.event.addListener(markerCluster, 'clusterclick', (cluster) => {

                    let customOverlay = document.querySelector('.GMAPICustomOverlay');

                    if(customOverlay !== null){

                        customOverlay.remove();
                    }

                    let initZoom = map.getZoom();

                    // если все маркеры в одном кластере то максимальный зум
                    if (checkCoordsInOnePoint(cluster) && (map.getZoom() < MAXZOOM)) {

                        if (activeOverlay && activeOverlay.cluster !== cluster){
                            activeOverlay.setMap(null);
                            activeOverlay = false;
                        }

                        showClusterInfo(cluster);

                    } else {

                        if (map.getZoom() < MAXZOOM) {

                            if (activeOverlay){
							activeOverlay.setMap(null);

							activeOverlay = false;
						}

                        map.setOptions({ maxZoom: MAXZOOM });
						map.fitBounds(cluster.getBounds());

                            if (map.getZoom() > 10 && map.getZoom() !== MAXZOOM) {
                                if (initZoom !== map.getZoom() - 1) {
                                    map.setZoom(map.getZoom() - 1);
                                }
                            }

                            map.setOptions({maxZoom: null});

                        } else {
                            showClusterInfo(cluster);
                        }
                    }
                });

                google.maps.event.addListener(markerCluster,'mouseover',(c) =>{
                    c.clusterIcon_.div_.className='clusterHover'
                });

                google.maps.event.addListener(markerClusterChecked,'mouseover',(c) =>{
                    c.clusterIcon_.div_.className='clusterHover'
                });

                google.maps.event.addListener(markerCluster,'mouseout',(c) =>{
                    c.clusterIcon_.div_.className=''
                });

                google.maps.event.addListener(markerClusterChecked,'mouseout',(c) =>{
                    c.clusterIcon_.div_.className=''
                });


                google.maps.event.addListener(markerClusterChecked, 'clusterclick', (cluster) => {

                    let customOverlay = document.querySelector('.GMAPICustomOverlay');

                    if(customOverlay !== null){
                        customOverlay.remove();
                    }

                    let initZoom = map.getZoom();

                    // если все маркеры в одном кластере то максимальный зум
                    if (checkCoordsInOnePoint(cluster) && (map.getZoom() < MAXZOOM)) {

                        if (activeOverlay && activeOverlay.cluster !== cluster){
                            activeOverlay.setMap(null);
                            activeOverlay = false;
                        }

                        showClusterInfo(cluster);

                    } else {

                        if (map.getZoom() < MAXZOOM) {

                            if (activeOverlay){
							activeOverlay.setMap(null);

							activeOverlay = false;
						}

                        map.setOptions({ maxZoom: MAXZOOM });
						map.fitBounds(cluster.getBounds());


                            if (map.getZoom() > 10 && map.getZoom() !== MAXZOOM) {
                                if (initZoom !== map.getZoom() - 1) {
                                    map.setZoom(map.getZoom() - 1);
                                }
                            }

                            map.setOptions({maxZoom: null});

                        } else {
                            showClusterInfo(cluster);
                        }
                    }
                });

                map.fitBounds(bounds);
                map.panToBounds(bounds);

                google.maps.event.addListener(map, 'zoom_changed', function () {


                    if(map.getZoom() > 20) {
                        map.setZoom(17);
                    }


                if (activeOverlay && activeOverlay.cluster){
					activeOverlay.setMap(null);
					activeOverlay = false;
				}

                    let zoom = map.getZoom();

                    if (zoom <= 10) {
                        markerCluster.setGridSize(60);
                    } else if ((10 < zoom) && (zoom <= 12)) {
                        markerCluster.setGridSize(50);
                    } else if ((12 < zoom) && (zoom <= 14)) {
                        markerCluster.setGridSize(35);
                    } else if ((14 < zoom) && (zoom <= 16)) {
                        markerCluster.setGridSize(30);
                    } else {
                        markerCluster.setGridSize(10);
                    }

                });
            }
        }))
    })
}
