export default function mobileMenu(){
    let mobileBtn = document.getElementsByClassName('mobile-menu-btn')[0],
        mobileMenu = document.getElementsByClassName('mobile-menu')[0],
        closeMobileBtn = document.getElementsByClassName('mobile-menu__close ')[0],
        body = document.getElementsByTagName('body')[0],
        mobileContainer = document.getElementsByClassName('mobile-menu-container')[0],
        vacansyBtn = document.getElementsByClassName('vacansy-title-btn'),
        vacansyText = document.getElementsByClassName('vacansy-text'),
        articleShare = document.getElementsByClassName('article-container-panel__item_share')[0],
        articleSharePanel = document.getElementsByClassName('article-container-panel_share')[0];

if(mobileBtn !== undefined && mobileBtn !== null){
    mobileBtn.addEventListener('click', function (e) {
        e.preventDefault();
        mobileMenu.classList.add('mobile-menu_active');
        mobileContainer.classList.add('mobile-menu-container_active');
        body.style.overflow = "hidden";
    });
}

    if(closeMobileBtn !== undefined && closeMobileBtn !== null){
    closeMobileBtn.addEventListener('click', function (e) {
        e.preventDefault();
        mobileMenu.classList.remove('mobile-menu_active');
        mobileContainer.classList.remove('mobile-menu-container_active');
        body.style.overflow = "";
    });
}}