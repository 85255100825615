let dataInputsContainer = document.querySelector('.search-container__item_street');

if (dataInputsContainer !== undefined && dataInputsContainer !== null) {
	let dataInputs = dataInputsContainer.querySelectorAll('.search-container__item__input');
	let street = document.querySelector('#street');


	dataInputs.forEach(item => {


		let input = item;

		let autoCompleteContainer = document.createElement('div');
		autoCompleteContainer.classList.add('search-container__item__text');
		autoCompleteContainer.style.display = 'none';

		item.parentNode.appendChild(autoCompleteContainer);

		let inputValue = input.value;

		let fetcher = (query, cb) => {

			let cityContainer = document.querySelector('.search-container__item_city');
			let city = cityContainer.querySelector('.search-container__item__input');
			let index = city.textContent;
			let text = index;

			console.log(text);

			fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Token 485ba84a0c7d4e5fbeb11fe269de779af89ed956',
				},
				body: JSON.stringify({
					query: text + "  " + query,
					count: 6,
					from_bound: {
						value: 'street'
					},
					to_bound: {
						value: 'street'
					},
					restrict_value: false
				})
			})
			.then()
			.then(res => res.json())
			.then(json => {

				let items = [];
				json.suggestions.forEach(item =>{
					items.push(item.data.street_type ? item.data.street_type + ' ' + item.data.street : item.data.street);
				});

				completeRender(items);

			});
		};

		input.addEventListener('keyup',function (e) {

			let newValue = input.value;
			let keyKode = e.keyCode;

			switch (keyKode) {
				case 27 : { // esc
					input.blur();
					completeRender([]);
					break;
				}
				case 40 : { // down
					e.preventDefault();
					let childLength = autoCompleteContainer.childNodes.length;
					let select = autoCompleteContainer.querySelector('.dadata-input__complete-item_select');

					if (select) {
						if (autoCompleteContainer.childNodes[(childLength - 1)] === select) {
							select.classList.remove('dadata-input__complete-item_select');
							autoCompleteContainer.childNodes[0].classList.add('dadata-input__complete-item_select');
						} else {
							select.classList.remove('dadata-input__complete-item_select');
							select.nextElementSibling.classList.add('dadata-input__complete-item_select');
						}
					} else {
						if (childLength !== 0){
							autoCompleteContainer.childNodes[0].classList.add('dadata-input__complete-item_select');
						}
					}
					break;
				}
				case 38 : { // top
					e.preventDefault();
					let childLength = autoCompleteContainer.childNodes.length;
					let select = autoCompleteContainer.querySelector('.dadata-input__complete-item_select');

					if (select) {
						if (autoCompleteContainer.childNodes[0] === select) {
							select.classList.remove('dadata-input__complete-item_select');
							autoCompleteContainer.childNodes[(childLength - 1)].classList.add('dadata-input__complete-item_select');
						} else {
							select.classList.remove('dadata-input__complete-item_select');
							select.previousElementSibling.classList.add('dadata-input__complete-item_select');
						}
					} else {
						if (childLength !== 0){
							autoCompleteContainer.childNodes[(childLength - 1)].classList.add('dadata-input__complete-item_select');
						}
					}
					break;
				}
				case 13 : { // enter
					e.preventDefault();

					let select = autoCompleteContainer.querySelector('.dadata-input__complete-item_select');

					if (select) {
						input.value = select.getAttribute('data-value');
					}

					input.blur();


					return false;
				}
				default : {
					if (inputValue !== newValue || newValue === '') {
						if (newValue.length >= 3) {
							fetcher(newValue);
						} else {
							completeRender([]);
						}
					}
				}

			}
		});

		// disable form submit
		input.addEventListener('keypress',(e) => {
			switch (e.keyCode) {
				case 13 : {
					e.preventDefault();
					return false;
				}
				case 40 : { // down
					e.preventDefault();
					break;
				}
				case 38 : { // top
					e.preventDefault();
					break;
				}
			}
		});

		document.addEventListener('mouseup', (e) => {
			let top_walker = (node, test_func, last_parent) => {
				while ( node && node !== last_parent ) {
					if ( test_func(node) ) {
						return node;
					}
					node = node.parentNode;
				}
			};

			let isClickWithinOpenedDiv = top_walker(event.target, function (node) {
				return node === input.parentElement;
			});

			if (!isClickWithinOpenedDiv) {
				completeRender([]);
			}
		});

		let completeRender = (streetItems) => {

			if (streetItems.length) {
				let limit = 6;
				autoCompleteContainer.innerHTML = '';
				if (streetItems.length){
					streetItems.forEach(streetItem => {
						if (limit > 0) {
							let streetItemElem = document.createElement('div');
							streetItemElem.classList.add('select-text');
							streetItemElem.setAttribute('data-value', streetItem);
							streetItemElem.innerHTML = streetItem;
							streetItemElem.addEventListener('mousedown', function () {
								input.value = streetItem;
								street.value = streetItem;
								completeRender([]);
							});
							autoCompleteContainer.appendChild(streetItemElem);
							limit--;
						}
					});
				}
				autoCompleteContainer.style.display = 'block';
				autoCompleteContainer.style.visibility = 'visible';
			} else {
				autoCompleteContainer.innerHTML = '';
				autoCompleteContainer.style.display = 'none';
			}

		};

	});
}





