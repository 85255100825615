export default function vacansyBtn(){
    let vacansyText = document.querySelectorAll('.vacansy-text'),
        vacansyBtn = document.querySelectorAll('.vacansy-title'),
        vacansyTitleBtn = document.querySelectorAll('.vacansy-title-btn');

    function showVacansy(b) {
        vacansyText[b].classList.toggle('vacansy-text_show');
        vacansyTitleBtn[b].classList.toggle('vacansy-title-btn_show');

    }

    for (let i = 0; i < vacansyBtn.length; i++) {
        vacansyBtn[i].addEventListener('click', function (e) {
                    showVacansy(i);
        })
    }
        document.querySelectorAll('.vacansy-item').forEach(item => {
        let vacansyModal = document.querySelector('.modal-vacansy'),
            vacansyInput = vacansyModal.querySelector('.vacansy-input'),
            vacansyTitle = vacansyModal.querySelector('.vacansy-title-js');

        let vacansyNameAttr = item.querySelector('.vacansy-title-post').getAttribute('data-vacansy-name'),
            vacansyName = item.querySelector('.vacansy-title-post'),
            vacansyBtn = item.querySelector('.vacansy-js');
        vacansyBtn.addEventListener('click', (e) => {
            vacansyInput.value = vacansyNameAttr;
            vacansyTitle.textContent = vacansyName.textContent;
        })

    })
}