export default function numberAnimation(){
    let number = document.querySelectorAll('.statistic-item__number span');


    number.forEach(item => {

        let numberId = item.getAttribute('data-number');

        let numberInt = parseInt(item.textContent, 10);

        let
            numberTop = item.getBoundingClientRect().top,
            i = 1,
            end = numberInt;

        window.addEventListener('scroll', function onScroll() {
            if(window.pageYOffset > numberTop - window.innerHeight / 2) {
                this.removeEventListener('scroll', onScroll);
                let interval = setInterval(() => {

                    item.innerHTML = Math.floor((numberId / 300) * i);
                    i++;
                    if(i === 300) {
                        item.innerHTML = numberId;
                        clearInterval(interval);
                    }
                },  3);
                // requestAnimationFrame(function animate(time) {
                //     if (start < end){
                //         item.innerHTML = ++start;
                //     }
                // })
            }
        });
    })
}