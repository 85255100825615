export default function print(){
    document.querySelectorAll('.print').forEach(item => {
        item.addEventListener('click', (e) => {
            let iframeCreated = document.querySelector('iframe');
            if(iframeCreated !== null && iframeCreated !== undefined){
                iframeCreated.remove();
            }
            e.preventDefault();
            let iframe = document.createElement('iframe'),
                body = document.querySelector('body'),
                url = item.getAttribute('href');

            iframe.setAttribute('src', url);
            iframe.classList.add('good-print-iframe');

            body.appendChild(iframe);

            setTimeout(() => {
                iframe.contentWindow.print()
            }, 1000)
        })
    })
}