import IMask from "imask";

export default function modal() {
    let requestModalButton = document.querySelectorAll('.request-js'),
        callbackModalButton = document.querySelectorAll('.callback-js'),
        creditModalButton = document.querySelectorAll('.credit-js'),
        vacansyModalButton = document.querySelectorAll('.vacansy-js'),
        mailButton = document.querySelectorAll('.mail-js'),
        modalOverlay = document.querySelector('.overlay'),
        modalAll = document.querySelectorAll('.modal'),
        modal = document.querySelector('.modal'),
        modalRequest = document.querySelector('.modal-request'),
        modalVacansy = document.querySelector('.modal-vacansy'),
        modalCredit = document.querySelector('.modal-credit'),
        modalMail = document.querySelector('.modal-mail'),
        modalCallback = document.querySelector('.modal-callback'),
        body = document.querySelector('body'),
        mobileMenu = document.querySelector('.mobile-menu-container');

    let resultTextAccept = '<a class="modal-close">\n' +
                            '<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<svg width="36px" height="36px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
                            '<path d="M22.47375,19.94125 L36.98375,5.495 C37.67,4.81125 37.67375,3.705 36.99125,3.0175 C36.3075,2.32875 35.2025,2.3275 34.51625,3.01 L20.00125,17.45875 L5.61375,3.01625 C4.93375,2.3325 3.825,2.33 3.14,3.0125 C2.455,3.69625 2.45375,4.805 3.13625,5.49125 L17.52,19.93 L3.01625,34.3675 C2.33,35.05125 2.32625,36.1575 3.00875,36.845 C3.35,37.18875 3.8,37.36125 4.25,37.36125 C4.69625,37.36125 5.1425,37.19125 5.48375,36.85125 L19.99,22.41 L34.50875,36.98375 C34.85125,37.32875 35.3,37.5 35.74875,37.5 C36.19625,37.5 36.64375,37.32875 36.985,36.98875 C37.66875,36.305 37.67125,35.19875 36.98875,34.5125 L22.47375,19.94125 Z" id="Path"></path>\n' +
                            '\n' +
                            '</svg>\n' +
                            '</a>' +
                            '<div class="modal-result__icon"><img src="/static/assets/img/icons/success.png"></div>' +
                            '<div class="modal-title h3">Отлично!</div>' +
                            '<div class="modal-text">Ваша заявка была успешно отправлена!\n' +
                            'Скоро мы свяжемся с вами для обсуждения деталей!</div>' +
                            '<a href="javascript:;" class="btn btn_orange">Закрыть</a>'

    let resultTextFail = '<a class="modal-close">\n' +
                            '<?xml version="1.0" encoding="UTF-8"?>\n' +
                            '<svg width="36px" height="36px" viewBox="0 0 38 38" version="1.1" xmlns="http://www.w3.org/2000/svg">\n' +
                            '<path d="M22.47375,19.94125 L36.98375,5.495 C37.67,4.81125 37.67375,3.705 36.99125,3.0175 C36.3075,2.32875 35.2025,2.3275 34.51625,3.01 L20.00125,17.45875 L5.61375,3.01625 C4.93375,2.3325 3.825,2.33 3.14,3.0125 C2.455,3.69625 2.45375,4.805 3.13625,5.49125 L17.52,19.93 L3.01625,34.3675 C2.33,35.05125 2.32625,36.1575 3.00875,36.845 C3.35,37.18875 3.8,37.36125 4.25,37.36125 C4.69625,37.36125 5.1425,37.19125 5.48375,36.85125 L19.99,22.41 L34.50875,36.98375 C34.85125,37.32875 35.3,37.5 35.74875,37.5 C36.19625,37.5 36.64375,37.32875 36.985,36.98875 C37.66875,36.305 37.67125,35.19875 36.98875,34.5125 L22.47375,19.94125 Z" id="Path"></path>\n' +
                            '\n' +
                            '</svg>\n' +
                            '</a>' +
                            '<div class="modal-result__icon"><img src="/static/assets/img/icons/cancel.png"></div>' +
                            '<div class="modal-title h3">Ошибка :(</div>' +
                            '<div class="modal-text">Не удалось отправить заявку. Вернитесь и попробуйте отправить заявку повторно.</div>' +
                            '<a href="javascript:;" class="btn btn_white">Назад</a>'


    modalAll.forEach(function (item) {
        let closeBtn = item.querySelector('.modal-close');

        requestModalButton.forEach(function (item) {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                modalOverlay.classList.add('overlay_active');
                modalRequest.classList.add('modal-request_active');
                body.style.overflow = "hidden";
            });
        });
        creditModalButton.forEach(function (item) {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                modalOverlay.classList.add('overlay_active');
                modalCredit.classList.add('modal-credit_active');
                body.style.overflow = "hidden";
            });
        });
        vacansyModalButton.forEach(function (item) {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                modalOverlay.classList.add('overlay_active');
                modalVacansy.classList.add('modal-vacansy_active');
                body.style.overflow = "hidden";
            });
        });
        callbackModalButton.forEach(function (item) {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                modalOverlay.classList.add('overlay_active');
                modalCallback.classList.add('modal-callback_active');
                body.style.overflow = "hidden";
            });
        });
        mailButton.forEach(function (item) {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                modalOverlay.classList.add('overlay_active');
                modalMail.classList.add('modal-mail_active');
                body.style.overflow = "hidden";
            });
        });

        modalOverlay.addEventListener('click', (e) => {
            modalOverlay.classList.remove('overlay_active');
            item.classList.remove('modal-request_active');
            item.classList.remove('modal-callback_active');
            item.classList.remove('modal-vacansy_active');
            item.classList.remove('modal-mail_active');
            let modalResult = document.querySelector('.modal-result');
            if (modalResult !== null && modalResult !== undefined){
                modalResult.remove();
            }
            item.classList.remove('modal-vacansy_active');
            item.classList.remove('modal-credit_active');
            body.style.overflow ="";
            let modalRemove = document.querySelector('.modal-remove');
            if (modalRemove !== null && modalRemove !== undefined){
                modalRemove.remove();
            }
            let modalVideo = document.querySelector('.modal-video');
            if(modalVideo !== null && modalVideo !== undefined){
                modalVideo.remove();
            }
        });
        closeBtn.addEventListener('click', (e) => {
            e.preventDefault();
            modalOverlay.classList.remove('overlay_active');
            item.classList.remove('modal-request_active');
            item.classList.remove('modal-callback_active');
            item.classList.remove('modal-vacansy_active');
            item.classList.remove('modal-credit_active');
            item.classList.remove('modal-mail_active');
            if(!mobileMenu.classList.contains('.mobile-menu-container_active')){
                body.style.overflow ="";
            }
            let modalResult = document.querySelector('.modal-result');
            if (modalResult !== null && modalResult !== undefined){
                modalResult.remove();
            }
            let modalRemove = document.querySelector('.modal-remove');
            if (modalRemove !== null && modalRemove !== undefined){
                modalRemove.remove();
            }
            let modalVideo = document.querySelector('.modal-video');
            if(modalVideo !== null && modalVideo !== undefined){
                modalVideo.remove();
            }
        });
        item.addEventListener('click', (e) => {
            e.stopPropagation();
        });
    });

    let forms = document.querySelectorAll('.form');

    forms.forEach(item => {
        let
            inputName = item.querySelector('.form-input_name input'),
            inputPhone = item.querySelector('.form-input_telephone input'),
            inputCheckbox = item.querySelector('.form-input_checkbox input'),
            inputErrorPhone = item.querySelector('.form-input_telephone .form-input__error'),
            inputErrorMail = item.querySelector('.form-input_mail .form-input__error'),
            inputErrorName = item.querySelector('.form-input_name .form-input__error'),
            inputErrorMessage = item.querySelector('.form-input_textarea .form-input__error'),
            inputErrorCheckbox = item.querySelector('.form-input_checkbox .form-input__error'),
            allInputsContainer = item.querySelectorAll('.form-input'),
            vacansyName = item.querySelector('.vacansy-input'),
            inputMessage = item.querySelector('.form-input_textarea textarea'),
            inputMail = item.querySelector('.form-input_mail input'),
            allInputs = item.querySelectorAll('input'),
            modalBtn = item.querySelector('button'),
            objectToken;

        let formAttr = item.getAttribute('action');

        if (inputMessage !== undefined && inputMessage !== null) {
            inputMessage.addEventListener('change', () => {
                inputMessage.value.trim();
                if (inputMessage.value !== '') {
                    inputMessage.classList.add('ok');
                    inputMessage.classList.remove('false');
                    inputErrorMessage.classList.remove('form-input__error_active');
                } else {
                    inputMessage.classList.remove('ok');
                    inputMessage.classList.add('false');
                    inputErrorMessage.classList.add('form-input__error_active');
                }
            });
        }

        if (inputCheckbox !== undefined && inputCheckbox !== null) {
        inputCheckbox.addEventListener('change', () => {
            if (inputCheckbox.checked) {
                inputCheckbox.classList.add('ok');
                inputCheckbox.classList.remove('false');
                inputErrorCheckbox.classList.remove('form-input__error_active');
            } else {
                inputCheckbox.classList.add('false');
                inputCheckbox.classList.remove('ok');
                inputErrorCheckbox.classList.add('form-input__error_active');
            }
        })};

        if (inputName !== undefined && inputName !== null) {
        inputName.addEventListener('input', () => {
            if (inputName.value.match(/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/u)) {
                inputName.classList.add('ok');
                inputName.classList.remove('false');
                inputErrorName.classList.remove('form-input__error_active');
            }
        })};

        if (inputName !== undefined && inputName !== null){
        inputName.addEventListener('change', (e) => {
            if (inputName.value.match(/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/u)) {
                inputName.classList.add('ok');
                inputName.classList.remove('false');
                inputErrorName.classList.remove('form-input__error_active');

            } else if (!inputName.value.match(/^[a-zA-Zа-яА-Я'][a-zA-Zа-яА-Я-' ]+[a-zA-Zа-яА-Я']?$/u)) {
                inputName.classList.add('false');
                inputName.classList.remove('ok');
                inputErrorName.classList.add('form-input__error_active');
            } else {
                inputName.classList.remove('false');
                inputName.classList.remove('ok');
                inputErrorName.classList.remove('form-input__error_active');
            }
        })};


        if (inputPhone !== undefined && inputPhone !== null){
        inputPhone.addEventListener('input', (e) => {
            if (inputPhone.value.replace(/\D/g, '').substr(10, 10)) {
                inputPhone.classList.add('ok');
                inputPhone.classList.remove('false');
                inputErrorPhone.classList.remove('form-input__error_active');
            }
        })};
        if (inputPhone !== undefined && inputPhone !== null){
        inputPhone.addEventListener('change', (e) => {
            if (inputPhone.value.replace(/\D/g, '').substr(10, 10)) {
                inputPhone.classList.add('ok');
                inputPhone.classList.remove('false');
                inputErrorPhone.classList.remove('form-input__error_active');
            } else if (!inputPhone.value.replace(/\D/g, '').substr(10, 10)) {
                inputPhone.classList.add('false');
                inputPhone.classList.remove('ok');

            } else {
                inputPhone.classList.remove('false');
                inputPhone.classList.remove('ok');
                inputErrorPhone.classList.remove('form-input__error_active');
            }
        })};
        if (inputMail !== undefined && inputMail !== null) {
            inputMail.addEventListener('change', () => {
                if (inputMail.value !== '' && inputMail.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                    inputMail.classList.add('ok');
                    inputMail.classList.remove('false');
                    inputErrorMail.classList.remove('form-input__error_active');
                } else if (inputMail.value !== '' && !inputMail.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                    inputMail.classList.add('false');
                    inputMail.classList.remove('ok');
                    inputErrorMail.classList.add('form-input__error_active');
                } else if (inputMail.value === '') {
                    inputMail.classList.remove('false');
                    inputMail.classList.remove('ok');
                    inputErrorMail.classList.remove('form-input__error_active');
                }
            })
        }

        if (inputMail !== undefined && inputMail !== null) {
            inputMail.addEventListener('input', () => {
                if (inputMail.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
                    inputMail.classList.add('ok');
                    inputMail.classList.remove('false');
                    inputErrorMail.classList.remove('form-input__error_active');
                }
            })}

        modalBtn.addEventListener('click', (e) => {
            allInputsContainer.forEach(function (inputItem) {
                let input = inputItem.querySelector('input'),
                    error = inputItem.querySelector('.error');
                if (!input.classList.contains('ok') && input.hasAttribute('required')) {
                    input.classList.add('false');
                    error.classList.add('form-input__error_active');
                    e.preventDefault();
                    if (input.classList.contains('false')) {
                        error.classList.add('form-input__error_active');
                    }
                } else {
                    input.classList.remove('false');
                    error.classList.remove('form-input__error_active');
                }
            });
        });

        if (item.classList.contains('form-mail')) {

            const formData = new FormData();

            let objectIdContainer = document.querySelector('.good-state'),
                objectId;

            if(objectIdContainer !== null){
                objectId = objectIdContainer.getAttribute('data-map');
            }

            item.addEventListener('submit', (e) => {
                modalBtn.disabled = true;

                formData.append('email', inputMail.value);
                formData.append('object_id', objectId);

                e.preventDefault();
                fetch(formAttr, {
                        method: 'POST',
                        body: formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextAccept;
                        modalOverlay.appendChild(resultForm);
                        modalMail.classList.remove('modal-mail_active');
                        modalBtn.disabled = false;
                    } else {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextFail;
                        modalOverlay.appendChild(resultForm);
                        modalMail.classList.remove('modal-mail_active');
                        modalBtn.disabled = false;
                    }
                }).then(function (result) {
                });
            });
        }

        if (item.classList.contains('form-callback')) {

            if(objectToken === undefined && objectToken === null){
                objectToken = ''
            } else {
                objectToken = item.querySelector('input[name="csrfmiddlewaretoken"]').value;
            }

            const formData = new FormData();

            item.addEventListener('submit', (e) => {
                modalBtn.disabled = true;

            formData.append('name', inputName.value);
            formData.append('phone', inputPhone.value);
            formData.append('csrfmiddlewaretoken', objectToken);

                e.preventDefault();
                fetch(formAttr, {
                        method: 'POST',
                        body: formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextAccept;
                        modalOverlay.appendChild(resultForm);
                        modalCallback.classList.remove('modal-callback_active');
                        modalBtn.disabled = false;
                    } else {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextFail;
                        modalOverlay.appendChild(resultForm);
                        modalCallback.classList.remove('modal-callback_active');
                        modalBtn.disabled = false;
                    }
                }).then(function (result) {
                });
            });
        }
        if (item.classList.contains('form-request')) {

            if(objectToken === undefined && objectToken === null){
                objectToken = ''
            } else {
                objectToken = item.querySelector('input[name="csrfmiddlewaretoken"]').value;
            }

            let objectLink = item.querySelector('.request_link').value;

            const formData = new FormData();

            item.addEventListener('submit', (e) => {
                modalBtn.disabled = true;

            formData.append('name', inputName.value);
            formData.append('email', inputMail.value);
            formData.append('message', inputMessage.value);
            formData.append('phone', inputPhone.value);
            formData.append('object_link', objectLink);
            formData.append('csrfmiddlewaretoken', objectToken);

                e.preventDefault();
                fetch(formAttr, {
                        method: 'POST',
                        body:  formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextAccept;
                        modalOverlay.appendChild(resultForm);
                        modalRequest.classList.remove('modal-request_active');
                        modalBtn.disabled = false;
                    } else {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextFail;
                        modalOverlay.appendChild(resultForm);
                        modalRequest.classList.remove('modal-request_active');
                        modalBtn.disabled = false;
                    }
                }).then(function (result) {
                });
            });
        }

        if(item.classList.contains('form-credit')) {

            if(objectToken === undefined && objectToken === null){
                objectToken = ''
            } else {
                objectToken = item.querySelector('input[name="csrfmiddlewaretoken"]').value;
            }

            let objectLink = item.querySelector('.request_link').value;

             const formData = new FormData();

            item.addEventListener('submit', (e) => {
                modalBtn.disabled = true;

            formData.append('name', inputName.value);
            formData.append('email', inputMail.value);
            formData.append('message', inputMessage.value);
            formData.append('phone', inputPhone.value);
            formData.append('object_link', objectLink);
            formData.append('csrfmiddlewaretoken', objectToken);

                e.preventDefault();
                fetch(formAttr, {
                        method: 'POST',
                        body: formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextAccept;
                        modalOverlay.appendChild(resultForm);
                        modalCredit.classList.remove('modal-credit_active');
                        modalBtn.disabled = false;
                    } else {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextFail;
                        modalOverlay.appendChild(resultForm);
                        modalCredit.classList.remove('modal-credit_active');
                        modalBtn.disabled = false;
                    }
                }).then(function (result) {
                });
            });
        }
        if (item.classList.contains('form-vacansy')) {

            if(objectToken === undefined && objectToken === null){
                objectToken = ''
            } else {
                objectToken = item.querySelector('input[name="csrfmiddlewaretoken"]').value;
            }

            const formData = new FormData();


            item.addEventListener('submit', (e) => {

            let file = document.querySelector('#file').files[0];

            modalBtn.disabled = true;

            formData.append('name', inputName.value);
            formData.append('email', inputMail.value);
            formData.append('phone', inputPhone.value);
            formData.append('vacancy', vacansyName.value);
            formData.append('csrfmiddlewaretoken', objectToken);
            formData.append('resume', file,)

                e.preventDefault();

                fetch(formAttr, {
                        method: 'POST',
                        body: formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextAccept;
                        modalOverlay.appendChild(resultForm);
                        modalVacansy.classList.remove('modal-vacansy_active');
                        modalBtn.disabled = false;
                    } else {
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('modal', 'modal-result');
                        resultForm.innerHTML = resultTextFail
                        modalOverlay.appendChild(resultForm);
                        modalVacansy.classList.remove('modal-vacansy_active');
                        modalBtn.disabled = false;
                    }
                }).then(function (result) {
                });
            });
        }
        if (item.classList.contains('service-form')) {

            if(objectToken === undefined && objectToken === null){
                objectToken = ''
            } else {
                objectToken = item.querySelector('input[name="csrfmiddlewaretoken"]').value;
            }

            let serviceLink = item.querySelector('.legal-service-name').value;

            const formData = new FormData();

            item.addEventListener('submit', (e) => {

            modalBtn.disabled = true;

            formData.append('name', inputName.value);
            formData.append('email', inputMail.value);
            formData.append('phone', inputPhone.value);
            formData.append('csrfmiddlewaretoken', objectToken);
            formData.append('legal_service_name', serviceLink);

                e.preventDefault();
                fetch(formAttr, {
                        method: 'POST',
                        body: formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let serviceFormContainer = document.querySelector('.service-form-container');
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('service-form');
                        resultForm.innerHTML = '<div class="service-form-result">' +
                            '<div class="modal-result__icon"><img src="/static/assets/img/icons/success.png"></div>' +
                            '<div class="modal-title h3">Отлично!</div>' +
                            '<div class="modal-text">Ваша заявка была успешно отправлена!\n' +
                            'Скоро мы свяжемся с вами для обсуждения деталей!</div></div>';

                        item.remove();
                        serviceFormContainer.appendChild(resultForm);
                    } else {
                        let serviceFormContainer = document.querySelector('.service-form-container');
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('service-form');
                        resultForm.innerHTML = '<div class="service-form-result">' +
                            '<div class="modal-result__icon"><img src="/static/assets/img/icons/success.png"></div>' +
                            '<div class="modal-title h3">Ошибка :(!</div>' +
                            '<div class="modal-text">Не удалось отправить заявку. Вернитесь и попробуйте отправить заявку повторно.</div></div>';

                        item.remove();
                        serviceFormContainer.appendChild(resultForm);
                    }
                }).then(function (result) {
                });
            });
        }

        if (item.classList.contains('project-form-js')) {

            if(objectToken === undefined && objectToken === null){
                objectToken = ''
            } else {
                objectToken = item.querySelector('input[name="csrfmiddlewaretoken"]').value;
            }

            const formData = new FormData();

            item.addEventListener('submit', (e) => {

            formData.append('name', inputName.value);
            formData.append('phone', inputPhone.value);
            objectToken ? formData.append('csrfmiddlewaretoken', objectToken) : null;

                e.preventDefault();

                fetch(formAttr, {
                        method: 'POST',
                        body: formData
                    },
                ).then(function (response) {
                    if (response.status === 200) {
                        let projectFormContainer = document.querySelector('.project-form-container');
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('service-form');
                        resultForm.innerHTML = '<div class="modal-result__icon"><img src="/static/assets/img/icons/success.png"></div>' +
                            '<div class="modal-title h3">Отлично!</div>' +
                            '<div class="modal-text">Ваша заявка была успешно отправлена!\n' +
                            'Скоро мы свяжемся с вами для обсуждения деталей!</div>';

                        projectFormContainer.remove();
                        item.appendChild(resultForm);

                    } else {
                        let projectFormContainer = document.querySelector('.project-form-container');
                        let resultForm = document.createElement('div');
                        resultForm.classList.add('service-form');
                        resultForm.innerHTML = '<div class="modal-result__icon"><img src="/static/assets/img/icons/success.png"></div>' +
                            '<div class="modal-title h3">Ошибка :(!</div>' +
                            '<div class="modal-text">Не удалось отправить заявку. Вернитесь и попробуйте отправить заявку повторно.</div>';

                        projectFormContainer.remove();
                        item.appendChild(resultForm);
                    }
                }).then(function (result) {
                });
            });
        }

        if (inputPhone !== null) {
            var phoneMask = new IMask(
                inputPhone, {
                    mask: '+{7} (000) 000-00-00'
                });
        }

    })
}
