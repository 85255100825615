export default function CSSelect(elem, autoClick = false) {

    let open = false;
    let firstClick = false;
    let autoSelectInput = false;
    let firstClickFilter = false;

    let idName = elem.getAttribute('id');

    let filterDom = document.createElement('div');
    filterDom.setAttribute('id', 'child_for_' + idName);

    let addClassesArray = elem.getAttribute('data-add_class');
    if (addClassesArray) {
        addClassesArray = addClassesArray.split(' ');
    } else {
        addClassesArray = [];
    }

    let dataJson = JSON.parse(elem.getAttribute('data-json'));
    // main input
    let selectedInput = document.createElement('div');
    selectedInput.classList.add('search-container__item__input');
    filterDom.appendChild(selectedInput);

    // items container
    let selectItemsContainer = document.createElement('div');
    selectItemsContainer.classList.add('search-container__item__text');
    filterDom.appendChild(selectItemsContainer);

    let insertAfter = (elem, refElem) => {
        let parent = refElem.parentNode;
        let next = refElem.nextSibling;
        if (next) {
            return parent.insertBefore(elem, next);
        } else {
            return parent.appendChild(elem);
        }
    };

    let closeEventListener = (e) => {
        if (firstClick) {
            firstClick = false;
        } else {
            if (!filterDom.contains(e.target)) {
                toggleSelect(false)
            }
        }
    };

    let setValueById = (id) => {
        let value = '';
        if (id) {
            dataJson.forEach(dataJsonItem => {
                if (+dataJsonItem.id === +id) {
                    value = dataJsonItem.title;
                    elem.value = dataJsonItem.id
                }
            });
            selectedInput.innerHTML = value;
        } else {
            selectedInput.innerHTML = '';
        }
    };

    setValueById(elem.value);

    let toggleSelect = (needOpen = true) => {
        if (needOpen) {
            firstClick = true;
            filterDom.classList.add('search-container__item_active');
            document.addEventListener('click', closeEventListener);
        } else {
            filterDom.classList.remove('search-container__item_active');
            document.removeEventListener('click', closeEventListener);
        }
        open = needOpen;
    };

    filterDom.classList.add('search-container__item');
    addClassesArray.forEach(addClassesArrayItem => {
        filterDom.classList.add(addClassesArrayItem)
    });

    // items add

    dataJson && dataJson.forEach(dataJsonItems => {
        let elemItems = document.createElement('div');
        elemItems.classList.add('select-text');
        elemItems.innerHTML = dataJsonItems.title;
        selectItemsContainer.appendChild(elemItems);

        elemItems.addEventListener('click', () => {
        if(dataJsonItems.id === "0" && elem.value === ""){
            elem.value = "";
            let selectedInputFirstName = elem.getAttribute('data-first-name');
            selectedInput.innerHTML = selectedInputFirstName.value;
            //
        }else if(dataJsonItems.title === "Торговые центры"){
            let city = window.location.pathname.split('/')[2];
            let transaction = window.location.pathname.split('/')[3];
            if (transaction == undefined){
                if (document.querySelector('#child_for_buy_type .search-container__item__input').innerHTML=='Купить'){
                    location.href='/catalog/'+document.querySelector('#city-for-marketplace').value+'/'+document.querySelector('#prodazha-for-marketplace').value+'/torgovye-centry/';
                }else{
                    location.href='/catalog/'+document.querySelector('#city-for-marketplace').value+'/'+document.querySelector('#arenda-for-marketplace').value+'/torgovye-centry/';
                }
            }else{
                location.href='/catalog/'+city+'/'+transaction+'/torgovye-centry/';
            }
        } else {
            elem.value = dataJsonItems.id;
            selectedInput.innerHTML = dataJsonItems.title;
        }

            if ("createEvent" in document) {
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("change", false, true);
                elem.dispatchEvent(evt);
            } else {
                elem.fireEvent("onchange");
            }
            toggleSelect(false);
        });

        if (!autoSelectInput) {
            autoSelectInput = elemItems;
        }

    });

    elem.addEventListener('change', function (e) {
        setValueById(e.target.value);
    });

    insertAfter(filterDom, elem);

    if (autoClick && autoSelectInput) {
        autoSelectInput.click();
    }

    selectedInput.addEventListener('click', (e) => {
        e.preventDefault();
        toggleSelect(!open);
    });

}
