export default function squareMobile(){
    let squareFrom = document.getElementsByClassName('search-container__item_square_mobile')[0];
    let squareTo = document.getElementsByClassName('search-container__item_square_mobile')[1];

    if (squareFrom !== undefined && squareFrom !== null && squareTo !== undefined && squareTo !== null) {
        let squareIdFrom = squareFrom.getAttribute('data-id');
        let squareIdTo = squareTo.getAttribute('data-id');

        let inputSquareFrom = document.getElementById(squareIdFrom);
        let inputSquareTo = document.getElementById(squareIdTo);

        let squareInputFrom = squareFrom.querySelector('.square-mobile_from');
        let squareInputTo = squareTo.querySelector('.square-mobile_to');

        squareInputFrom.addEventListener('change', () => {
            inputSquareFrom.value = squareInputFrom.value;
        });
        squareInputTo.addEventListener('change', () => {
            inputSquareTo.value = squareInputTo.value;
    });
    } else {
        return false
    }
};