export default function geolocation(){
    let domainBtn = document.querySelectorAll('.domains-js');
        if(domainBtn !== null){
            domainBtn.forEach(item => {
                let domainList = item.getAttribute('data-city'),
                    open = false,
                    newList;

                domainList = JSON.parse(domainList);

                console.log(domainList);

                document.addEventListener('click', (e) =>{
                    if(open === true){
                        newList.remove();
                        open = false;
                    } else {

                    }
                });

                item.addEventListener('click', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if(!open){
                        newList = document.createElement('ul');
                        newList.classList.add('geolocation-list');
                        item.appendChild(newList);

                        domainList.forEach(item => {

                            let newItem = document.createElement('a');

                            newItem.innerHTML = item.title;

                            newItem.setAttribute('href', item.link);

                            newList.appendChild(newItem);

                            newItem.addEventListener('click', (e) => {
                                e.stopPropagation();
                            })

                        });

                        open = true;

                        return newList

                    } else {
                        newList.remove();

                        open = false;
                    }
                })
            });

    }

}