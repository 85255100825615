import IMask from "imask";

export default function price() {
    let priceFrom = document.createElement('div'),
        priceTo = document.createElement('div'),
        priceContainer = document.querySelector('.search-container__item_price');

    if (priceContainer === null) {
        return false
    }
    ;

    let priceInput = priceContainer.querySelector('.search-container__item__input'),
        priceItemContainer = document.createElement('div'),
        inputFrom = document.createElement('input'),
        inputTo = document.createElement('input'),
        button = document.createElement('a'),
        searchPlaceholdersFrom = document.createElement('div'),
        searchPlaceholdersTo = document.createElement('div');

    priceFrom.classList.add('buy-input', 'buy-input_from');
    priceTo.classList.add('buy-input', 'buy-input_to');

    searchPlaceholdersFrom.classList.add('search-container__item__placeholder_from', 'search-container__item__placeholder', 'md-hidden');
    searchPlaceholdersTo.classList.add('search-container__item__placeholder_to', 'search-container__item__placeholder', 'md-hidden');

    priceItemContainer.classList.add('search-container__item__inputs');
    inputFrom.classList.add('select-input-from');
    inputTo.classList.add('select-input-to');
    button.classList.add('btn', 'btn_white');
    button.textContent = 'Применить';

    priceInput.appendChild(priceFrom);
    priceInput.appendChild(priceTo);

    priceContainer.appendChild(priceItemContainer);

    priceItemContainer.appendChild(searchPlaceholdersFrom);
    priceItemContainer.appendChild(searchPlaceholdersTo);

    priceItemContainer.appendChild(inputFrom);
    priceItemContainer.appendChild(inputTo);

    priceItemContainer.appendChild(button);

    searchPlaceholdersFrom.innerHTML = '<span>до</span><span>₽</span>';
    searchPlaceholdersTo.innerHTML = '<span>от</span><span>₽</span>';

    priceFrom.innerHTML = 'Стоимость';

    let inputFromId = document.querySelector('#price_from'),
        inputToId = document.querySelector('#price_to');

    inputTo.value = inputToId.value;
    inputFrom.value = inputFromId.value;

    var numberMaskFrom = IMask(
      inputFrom,
      {
        mask: Number,
        thousandsSeparator: ' '
      });

    var numberMaskTo = IMask(
      inputTo,
      {
        mask: Number,
        thousandsSeparator: ' '
    });


        if(inputFrom.value === '' && inputTo.value === '') {
            priceFrom.innerHTML = "Стоимость";
        } else if(inputFrom.value === '' && inputTo.value !== ''){
            priceFrom.innerHTML =  'до ' + abbreviateNumber(inputTo.value.replace(/\s/g, ""));
            priceTo.innerHTML = '';
        } else if(inputFrom.value !== '' && inputTo.value === ''){
           priceFrom.innerHTML = "от " + abbreviateNumber(inputFrom.value.replace(/\s/g, ""));
           priceTo.innerHTML = '';
        } else if(inputFrom.value !== ''){
            priceFrom.innerHTML = "от " + abbreviateNumber(inputFrom.value.replace(/\s/g, ""));
        } else if(inputFrom.value !== '' && inputTo.value !== ''){
            priceFrom.innerHTML = "от " + abbreviateNumber(inputFrom.value.replace(/\s/g, ""));
            priceTo.innerHTML =  'до ' + abbreviateNumber(inputTo.value.replace(/\s/g, ""));
        }
        else {
            priceFrom.innerHTML = "от " + abbreviateNumber(inputFrom.value.replace(/\s/g, ""));
            priceTo.innerHTML = "до " + abbreviateNumber(inputTo.value.replace(/\s/g, ""));
        }


    button.addEventListener('click', (e) => {
        e.preventDefault();
        let inputFromId = document.querySelector('#price_from'),
            inputToId = document.querySelector('#price_to');

        priceFrom.innerHTML = inputFrom.value;
        priceTo.innerHTML = inputTo.value;



        if(priceFrom.textContent === '' && priceTo.textContent === '') {
            priceFrom.innerHTML = "Стоимость";
            priceTo.innerHTML = ""
        } else if(priceFrom.textContent === '' && priceTo.textContent !== ''){
            priceFrom.innerHTML =  'до ' + abbreviateNumber(priceTo.textContent.replace(/\s/g, ""));
            priceTo.innerHTML = '';
        } else if(priceFrom.textContent !== '' && priceTo.textContent === ''){
           priceFrom.innerHTML = "от " + abbreviateNumber(priceFrom.textContent.replace(/\s/g, ""));
           priceTo.innerHTML = '';
        } else {
            priceFrom.innerHTML = "от " + abbreviateNumber(priceFrom.textContent.replace(/\s/g, ""));
            priceTo.innerHTML = "до " + abbreviateNumber(priceTo.textContent.replace(/\s/g, ""));
        }

        inputFromId.value = inputFrom.value.replace(/\s/g, "");
        inputToId.value = inputTo.value.replace(/\s/g, "");

        priceContainer.classList.remove('search-container__item_active');

    });

    function abbreviateNumber(value) {
        let newValue = value;
        if (value >= 1000) {
            let suffixes = ['', 'тыс.', 'млн', 'млрд', 'трлн'];
            let suffixNum = Math.floor(('' + value).length / 3);
            let shortValue = '';
            for (let precision = 2; precision >= 1; precision--) {
                shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision));
                let dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                if (dotLessShortValue.length <= 2) {
                    break
                }
            }
            if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1);
            newValue = shortValue + " " + suffixes[suffixNum]
        }
        return newValue
    }

}


