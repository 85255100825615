export default function latentLinkFollowing() {
    let latentLinks = document.querySelectorAll('.latent-link-following');
    latentLinks.forEach(function (latentLink) {
        latentLink.addEventListener('click', function (e) {
            if (this.getAttribute('target') === '_blank'){
                window.open(this.dataset.href);
            }else{
                location.href = this.dataset.href;
            }
        });
    });
}