export default function square() {

    let squareContainer = document.querySelector('.search-container__item_square');

    let squareFrom = document.createElement('div'),
        squareTo = document.createElement('div');
        // squareContainer = document.querySelector('.search-container__item_square');

    if (squareContainer === null){
        return false
    };

   let  squareInput = squareContainer.querySelector('.search-container__item__input'),
        squareItemContainer = document.createElement('div'),
        inputFrom = document.createElement('input'),
        inputTo = document.createElement('input'),
        button = document.createElement('a'),
        searchPlaceholdersFrom = document.createElement('div'),
        searchPlaceholdersTo = document.createElement('div');

        squareFrom.classList.add('buy-input', 'buy-input_from');
        squareTo.classList.add('buy-input', 'buy-input_to');

        searchPlaceholdersFrom.classList.add('search-container__item__placeholder_from', 'search-container__item__placeholder', 'md-hidden');
        searchPlaceholdersTo.classList.add('search-container__item__placeholder_to', 'search-container__item__placeholder', 'md-hidden');

        squareItemContainer.classList.add('search-container__item__inputs');
        inputFrom.classList.add('select-input-from');
        inputTo.classList.add('select-input-to');
        button.classList.add('btn', 'btn_white');

        button.textContent = 'Применить';


        squareInput.appendChild(squareFrom);
        squareInput.appendChild(squareTo);
        squareContainer.appendChild(squareItemContainer);
        squareItemContainer.appendChild(inputFrom);
        squareItemContainer.appendChild(inputTo);
        squareItemContainer.appendChild(button);
        squareItemContainer.appendChild(searchPlaceholdersFrom);
        squareItemContainer.appendChild(searchPlaceholdersTo);

        searchPlaceholdersFrom.innerHTML = '<span>до</span><span>м²</span>';
        searchPlaceholdersTo.innerHTML = '<span>от</span><span>м²</span>';

        inputFrom.setAttribute('value', '0');
        inputTo.setAttribute('value', '100000');

            let inputFromId = document.querySelector('#square_from'),
                inputToId = document.querySelector('#square_to');

            inputTo.value = inputToId.value;
            inputFrom.value = inputFromId.value;

        squareFrom.innerHTML = 'Площадь';

        button.addEventListener('click', (e) => {
            e.preventDefault();

            squareFrom.innerHTML = inputFrom.value;
            squareTo.innerHTML = inputTo.value;

            inputFromId.value = squareFrom.textContent;
            inputToId.value = squareTo.textContent;
        });

    let closeBtnContainer = document.querySelectorAll('.search-container__item_buy');
    if (closeBtnContainer !== null && closeBtnContainer !== undefined){
        closeBtnContainer.forEach(item => {
            let closeBtn = item.querySelector('.btn');
            if(closeBtn !== null && closeBtn !== undefined){
             closeBtn.addEventListener('click', (e) => {
                e.preventDefault();
                item.classList.remove('search-container__item_active');
            })
            }
        })
    }

    }