let CSInputStreet = (selectItem) => {


    let dataIdStreet = selectItem.getAttribute('data-id');
    let inputStreet = document.getElementById(dataIdStreet);
    let streetInput = selectItem.querySelector('.search-container__item__input');

    streetInput.addEventListener('change', () => {
        inputStreet.value = streetInput.value
    })

};

export default CSInputStreet;